<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Currencies</h3>
            <div class="card-tools">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                data-toggle="modal"
                data-target="#add-currency"
              >New</button>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="currency in currencies" v-bind:key="currency.id">
                  <td>{{ currency.id }}</td>
                  <td>{{ currency.name }}</td>
                  <td>
                    <button
                      @click="editcurrency(currency)"
                      type="button"
                      class="btn btn-sm btn-warning"
                      data-toggle="modal"
                      data-target="#edit-currency"
                    >Edit</button>
                    <button class="btn btn-sm btn-danger" @click="deletecurrency(currency)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>

    <!-- add currency Modal -->
    <div
      class="modal fade"
      id="add-currency"
      tabindex="-1"
      currency="dialog"
      aria-labelledby="addcurrencyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" currency="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addcurrencyLabel">New currency</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form v-on:submit.prevent>
            <div class="modal-body">
              <div class="form-group">
                <label for="add-name">
                  Name
                  <code>*</code>
                </label>
                <input
                  v-model="currencyName"
                  type="text"
                  class="form-control"
                  id="add-name"
                  placeholder="Enter Name"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
              <button @click="createcurrency()" type="button" class="btn btn-sm btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit currency Modal -->
    <div
      class="modal fade"
      id="edit-currency"
      tabindex="-1"
      currency="dialog"
      aria-labelledby="editcurrencyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" currency="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editcurrencyLabel">Edit currency</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form v-on:submit.prevent>
            <div class="modal-body">
              <div class="form-group">
                <label for="edit-name">
                  Name
                  <code>*</code>
                </label>
                <input
                  v-model="currency.name"
                  type="text"
                  class="form-control"
                  id="edit-name"
                  placeholder="Enter Name"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
              <button @click="updatecurrency()" type="button" class="btn btn-sm btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataTables from "datatables";

export default {
  methods: {
    checkAddValidity() {
      if (!this.currencyName) {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      return true;
    },
    checkEditValidity() {
      if (!this.currency.name) {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      return true;
    },
    createcurrency() {
      if (this.checkAddValidity()) {
        $("#add-currency").modal("toggle"); // close the modal

        this.currencies.push({
          name: this.currencyName,
          id: ++this.id
        });

        // reset
        this.currencyName = "";

        Swal.fire({
          position: "top-end",
          type: "success",
          title: "Currency created successfully",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    editcurrency(currency) {
      this.currency = currency;
    },
    updatecurrency() {
      if (this.checkEditValidity()) {
        $("#edit-currency").modal("toggle"); // close the modal

        Swal.fire({
          position: "top-end",
          type: "success",
          title: "Currency updated successfully",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    deletecurrency(currency) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.currencies.splice(this.currencies.indexOf(currency), 1);
        }
      });
    },
    loadCurrencies() {
      axios({
        method: "get",
        url: "/api/currencies"
      }).then(
        response => {
          this.currencies = response.data.data;
          setTimeout(() => {
            $(".table").DataTable();
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    }
  },

  data() {
    return {
      currencyName: "",
      id: 2,
      currency: {
        id: "",
        name: ""
      },
      currencies: []
    };
  },
  mounted() {
    this.loadCurrencies();
  }
};
</script>
