<template>
  <div class="row">
    <div class="col-md-12">
      <not-found v-if="!$gate.isMaster()"></not-found>

      <section class="content" v-if="$gate.isMaster()">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Sector Tree</h3>
            <div class="card-tools">
              <button
                id="AddModalButton"
                @click="handleAddModalButton"
                :class="{ 'disabled': !selectedNode||(selectedNode.data.type=='App\\SubSegment') }"
                type="button"
                class="btn btn-sm btn-success"
                data-toggle="modal"
                data-target="#add-tree"
              >
                <i class="fas fa-plus-circle"></i> New
              </button>

              <button
                id="EditModalButton"
                @click="handleEditModalButton()"
                :class="{ 'disabled': !selectedNode||!selectedNode.data.type||!form.edit_type }"
                type="button"
                class="btn btn-sm btn-warning"
                data-toggle="modal"
                data-target="#edit-tree"
              >
                <i class="fas fa-pencil-alt"></i> Edit
              </button>
              <button
                :class="{ 'disabled': !selectedNode||!selectedNode.data.type||!form.edit_type }"
                class="btn btn-sm btn-danger"
                @click="deleteTree()"
              >
                <i class="fas fa-trash"></i> Delete
              </button>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <tree ref="tree" :data="treeData" :options="treeOptions" v-model="selectedNode">
              <div class="tree-scope" slot-scope="{ node }">
                <template v-if="node.data.isRoot">
                  <!-- you can add an icon here, but it is too long for the demo... sorry  -->

                  <span class="text">{{ node.text }}</span>

                  <span class="release" v-if="!node.hasChildren()">{{ node.data.release }}</span>
                </template>
                <template v-else>
                  <!-- here we can leave a vue-router for example... -->

                  <span class="text">{{ node.text }}</span>
                </template>
              </div>
            </tree>
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="edit-tree"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addtreeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addtreeLabel">
                Edit
                <b>{{ (form.edit_type)?form.edit_type.split("\\")[1]:'??' }}</b>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form v-on:submit.prevent>
              <div class="modal-body">
                <div class="form-group">
                  <label for="add-name">
                    Name
                    <code>*</code>
                  </label>
                  <input
                    :class="{ 'is-invalid': form.errors.has('name') }"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    id="add-name"
                    placeholder="Enter name"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="updateTreeElement()"
                  type="button"
                  class="btn  btn-primary"
                >Save</button>

                <button
                  @click="form.id=0"
                  type="button"
                  class="btn  btn-secondary"
                  data-dismiss="modal"
                >Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="add-tree"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addtreeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addtreeLabel">
                Add
                <b>{{ (form.add_type)?form.add_type.split("\\")[1]:'??' }}</b>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form v-on:submit.prevent>
              <div class="modal-body">
                <div class="form-group">
                  <label for="add-name">
                    Name
                    <code>*</code>
                  </label>
                  <input
                    :class="{ 'is-invalid': form.errors.has('name') }"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    id="add-name"
                    placeholder="Enter name"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="storeTreeElement()"
                  type="button"
                  class="btn  btn-primary"
                >Save</button>

                <button type="button" class="btn  btn-secondary" data-dismiss="modal">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
img {
  height: 70px;
}
</style>
<script>
import dataTables from "datatables";

export default {
  methods: {
    editTree() {
      this.form = new Form({
        id: this.selectedNode.data.id,
        name: this.selectedNode.text,
        edit_type: this.selectedNode.data.type
      });
    },
    handleEditModalButton(e) {
      if ($("#EditModalButton").hasClass("disabled")) {
        e.stopPropagation();

        if (this.selectedNode) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Select something to edit"
          });
          return false;
        }
      } else {
        this.editTree();
        $("#edit-tree").modal("show");
      }
    },
    handleAddModalButton(e) {
      if ($("#AddModalButton").hasClass("disabled")) {
        e.stopPropagation();

        if (this.selectedNode) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "SubSegment can not be parent type"
          });
          return false;
        }

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You have to select the parent"
        });
      } else {
        $("#add-tree").modal("show");
      }
    },
    deleteTree() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "This will delete the node and it's children also you won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/tree-sectors/" + this.selectedNode.data.id, {
              params: {
                type: this.selectedNode.data.type
              }
            })
            .then(response => {
              this.loadTree();
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    getEditRoute(id) {
      return "/companies/" + id + "/edit";
    },
    updateTreeElement() {
      if (this.checkValidity()) {
        this.form.type = this.form.edit_type;
        this.$Progress.start();
        this.form
          .put("/api/tree-sectors/" + this.form.id)
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.loadTree();
            $("#edit-tree").modal("hide");
            this.form = new Form({
              name: "",
              add_type: "",
              edit_type: "",
              type: "",
              parent_id: "",
              parent_type: ""
            });

            this.$Progress.finish();
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    storeTreeElement() {
      if (this.checkValidity()) {
        this.form.type = this.form.add_type;
        this.$Progress.start();
        this.form
          .post("/api/tree-sectors")
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.loadTree();
            $("#add-tree").modal("hide");
            this.form = new Form({
              name: "",
              type: "",
              parent_id: "",
              parent_type: ""
            });
            this.$Progress.finish();
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    checkValidity() {
      return true;
    },
    loadTree() {
      axios({
        method: "get",
        url: "/api/tree-sectors",
        params: {
          TypeNotIn: "App\\Segment"
        }
      }).then(
        response => {
          this.$refs.tree.setModel({
            text: "Root",
            data: {
              id: 0
            },
            state: { expanded: true },
            children: response.data.data
          });
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  data() {
    return {
      selectedNode: null,
      treeOptions: {
        multiple: false
      },
      treeData: {},
      form: new Form({
        name: "",
        type: "",
        parent_id: "",
        parent_type: "",
        edit_type: "",
        add_type: ""
      }),
      types: {
        "App\\Sector": "App\\SubSector",
        "App\\SubSector": "App\\Segment",
        "App\\Segment": "App\\SubSegment"
      }
    };
  },
  mounted() {
    this.loadTree();
  },
  created() {},

  watch: {
    selectedNode: function(val) {
      this.form.parent_id = val.data.id;
      this.form.parent_type = val.data.type;
      if (val.data.type) {
        this.form.edit_type = val.data.type;
      } else {
        this.form.edit_type = "";
      }

      if (this.types[val.data.type]) {
        this.form.add_type = this.types[val.data.type];
      } else {
        this.form.add_type = "App\\Sector";
      }
    }
  }
};
</script>
