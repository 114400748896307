<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Create New Team</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body">
            <ul style="list-style-type: none;">
              <li
                class="alert alert-danger"
                v-for="error in team.errors.errors"
                v-bind:key="error"
              >{{ error[0] }}</li>
            </ul>
            <form v-on:submit.prevent>
              <div class="form-group">
                <label for="name">
                  Name
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': team.errors.has('name') }"
                  v-model="team.name"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Enter Name"
                />
                <has-error :form="team" field="name"></has-error>
              </div>
              <div
                class="form-group"
                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount()>1)"
              >
                <label for="company_id">
                  Company
                  <code>*</code>
                </label>
                <select
                  :class="{ 'is-invalid': team.errors.has('company_id') }"
                  v-model="team.company_id"
                  class="form-control"
                  id="company_id"
                >
                  <option value selected>Select Company</option>
                  <option
                    v-for="company in companies"
                    v-bind:value="company.id"
                    v-bind:key="company.id"
                  >{{ company.name}}</option>
                </select>
                <has-error :form="team" field="company_id"></has-error>
              </div>

              <div class="form-group">
                <label for="team_users">Users</label>
                <select
                  :class="{ 'is-invalid': team.errors.has('team_users') }"
                  v-model="team.team_users"
                  multiple
                  class="form-control"
                  id="team_users"
                >
                  <option
                    v-for="user in filteredUsers"
                    v-bind:value="user.id"
                    v-bind:key="user.id"
                  >{{user.name}}</option>
                </select>
                <has-error :form="team" field="team_users"></has-error>
              </div>

              <div class="form-group row">
                <div
                  class="col-sm-3 row mt-2"
                  v-for="(competency,index) in competencies"
                  v-bind:key="index"
                  :class="{ 'is-invalid': team.errors.has('skills') }"
                >
                  <label
                    class="col-sm-7 col-form-label"
                    v-bind:for="competency.name"
                  >{{ competency.name }}</label>
                  <input
                    @keyup="$inputIdentifyColorAndFeedBack($event)"
                    @input="$inputIdentifyColorAndFeedBack($event)"
                    @click="$inputIdentifyColorAndFeedBack($event)"
                    :placeholder="competency.name"
                    v-model="competency.standard_value"
                    type="number"
                    class="col-sm-3"
                  />
                  <button
                    class="btn btn-sm btn-danger custom-danger ml-2 col-sm-1"
                    @click="removeCompetency(competency)"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                  <div class="col-sm-2"></div>
                </div>
              </div>
              <has-error :form="team" field="skills"></has-error>

              <div class="form-group row" v-if="false&&competencies.length">
                <label for="moreCompetencies" class="label col-sm-2">More Competencies?</label>
                <div class="col-sm-5">
                  <select v-model="moreCompetency" class="form-control" id="moreCompetencies">
                    <option
                      v-for="Competency in allCompetenciesFiltered"
                      v-bind:key="Competency.id"
                      v-bind:value="Competency"
                    >{{Competency.name}}</option>
                  </select>
                </div>
                <div class="col-sm-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model="newValueToAdd"
                    placeholder="Enter the standard value for new competency"
                  />
                </div>
                <div class="col-sm-2">
                  <button v-on:click="addCompetency()" type="submit" class="btn btn-success">
                    <i class="fas fa-plus"></i> Add
                  </button>
                </div>
              </div>

              <button v-on:click="createTeam" type="submit" class="btn  btn-primary">Save</button>
              <router-link to="/team" class="btn btn-danger">Cancel</router-link>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.uploading-image {
  display: flex;
}
.col-form-label {
  white-space: nowrap;
}
</style>
<script>
export default {
  methods: {
    checkValidity() {
      if (!this.team.name) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      if (!this.team.company_id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Company is required"
        });

        return false;
      }

      return true;
    },
    createTeam() {
      if (this.checkValidity()) {
        this.$Progress.start();
        this.team
          .post("/api/teams")
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            this.$router.push({ name: "team.index" });
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    removeCompetency(competency) {
      delete this.team.skills[competency.id];
      this.competencies.splice(this.competencies.indexOf(competency), 1);
    },
    checkAddValidity() {
      if (!this.moreCompetency) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "A Competency is required"
        });

        return false;
      }

      if (!this.newValueToAdd) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: " The standard value is required"
        });

        return false;
      }

      if (
        parseInt(this.newValueToAdd) > 100 ||
        parseInt(this.newValueToAdd) <= 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: " The standard value must be between 1 and 100"
        });

        return false;
      }

      return true;
    },
    addCompetency() {
      if (this.checkAddValidity()) {
        this.moreCompetency["standard_value"] = parseInt(this.newValueToAdd);
        this.competencies.push(this.moreCompetency);

        this.newValueToAdd = ""; //reset
        this.moreCompetency = ""; //reset
      }
    },
    loadCompanies() {
      axios({
        method: "get",
        url: "/api/companies"
      }).then(
        response => {
          this.companies = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadUsers() {
      axios({
        method: "get",
        url: "/api/users"
      }).then(
        response => {
          this.users = response.data.data;
          if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
            this.team.company_id = this.$gate.getManagedCompanyId();
          }
        },
        error => {
          console.log(error);
        }
      );
    },
    loadCompetencies() {
      axios({
        method: "get",
        url: "/api/competencies"
      }).then(
        response => {
          this.allCompetencies = response.data.data;
          this.allCompetenciesFiltered = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  watch: {
    "team.team_users": {
      handler(value) {
        this.competencies = [];
        this.team.skills = {};
        value.forEach(userId => {
          let currentUser = this.users.filter(user => {
            return user.id == userId;
          })[0];

          let newSkills = currentUser.competencies;

          let newSkillsIds = newSkills.map(e => {
            return e.id;
          });

          let competenciesIds = this.competencies.map(e => {
            return e.id;
          });

          let identicalIds = newSkillsIds.filter(el =>
            competenciesIds.includes(el)
          );

          identicalIds.forEach(id => {
            let competency = this.competencies.filter(e => {
              return e.id == id;
            })[0];

            let skill = newSkills.filter(e => {
              return e.id == id;
            })[0];

            if (
              parseInt(skill.standard_value) >
              parseInt(competency.standard_value)
            ) {
              this.competencies[this.competencies.indexOf(competency)] = skill;
            }
          });

          newSkills = newSkills.filter(el => {
            return !identicalIds.includes(el.id);
          });
          this.competencies = this.competencies.concat(newSkills);
        });
      },
      deep: true
    },
    competencies: {
      handler(value) {
        // append to data that sent to backend
        value.forEach(competencyObject => {
          this.team.skills[competencyObject.id] = parseInt(
            competencyObject.standard_value
          );
        });

        // filter more competencies
        let currentCompetenciesIds = value.map(e => {
          return e.id;
        });
        this.allCompetenciesFiltered = this.allCompetencies.filter(el => {
          return !currentCompetenciesIds.includes(el.id);
        });
      },
      deep: true
    },
    "team.company_id": {
      handler(val) {
        this.filteredUsers = this.users.filter(user => {
          return user.company_id == val;
        });
      },
      deep: true
    }
  },
  data() {
    return {
      previewImage: null,
      companies: [],
      team: new Form({
        name: "",
        company_id: "",
        team_users: [],
        skills: []
      }),
      users: [],
      competencies: [],
      moreCompetency: "",
      allCompetencies: [],
      allCompetenciesFiltered: [],
      newValueToAdd: "",
      filteredUsers: []
    };
  },
  mounted() {
    this.loadCompanies();
    this.loadUsers();
    this.loadCompetencies();
  }
};
</script>
