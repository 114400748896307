<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Edit Branch</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body">
            <form v-on:submit.prevent>
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  :class="{ 'is-invalid': branch.errors.has('name') }"
                  v-model="branch.name"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Enter Name"
                />
                <has-error :form="branch" field="name"></has-error>
              </div>
              <div
                class="form-group"
                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount()>1)"
              >
                <label for="company_id">Company</label>
                <select
                  :class="{ 'is-invalid': branch.errors.has('company_id') }"
                  v-model="branch.company_id"
                  class="form-control"
                  id="company_id"
                >
                  <option value selected>Select Company</option>
                  <option
                    v-for="company in companies"
                    v-bind:value="company.id"
                    v-bind:key="company.id"
                  >{{ company.name}}</option>
                </select>
                <has-error :form="branch" field="company_id"></has-error>
              </div>

              <button v-on:click="editBranch" type="submit" class="btn  btn-primary">Save</button>
              <router-link to="/branches" class="btn  btn-danger">Cancel</router-link>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    checkValidity() {
      if (!this.branch.name) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }
      if (!this.branch.company_id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Company is required"
        });
        return false;
      }
      return true;
    },
    editBranch() {
      if (this.checkValidity()) {
        this.$Progress.start();
        this.branch
          .put("/api/branches/" + this.branch.id)
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            this.$router.push({ name: "branches.index" });
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    loadCompanies() {
      axios({
        method: "get",
        url: "/api/companies"
      }).then(
        response => {
          this.companies = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadCurrentBranch() {
      axios({
        method: "get",
        url: "/api/branches/" + this.$route.params.id
      })
        .then(response => {
          this.branch = new Form(response.data.data);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  },

  data() {
    return {
      companies: [],
      branch: new Form({
        name: "test_data",
        company_id: 1
      })
    };
  },
  mounted() {
    this.loadCompanies();
    this.loadCurrentBranch();
  }
};
</script>
