<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Training Details</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body">
            <form v-on:submit.prevent>
              <div class="form-group">
                <label for="user_id">
                  User
                  <code>*</code>
                </label>
                <select
                  v-model="training.user_id"
                  class="form-control bg-white"
                  id="user_id"
                  disabled
                >
                  <option value selected>Select User</option>
                  <option
                    v-for="user in users"
                    v-bind:value="user.id"
                    v-bind:key="user.id"
                  >{{ user.name}}</option>
                </select>
              </div>

              <div class="form-group">
                <label for="topic">
                  Topic
                  <code>*</code>
                </label>
                <input
                  v-model="training.topic"
                  type="text"
                  class="form-control bg-white"
                  id="topic"
                  placeholder="Enter Topic"
                  disabled
                />
              </div>
              <div class="form-group">
                <label for="method_id">
                  Method
                  <code>*</code>
                </label>
                <select
                  disabled
                  v-model="training.method_id"
                  class="form-control bg-white"
                  id="method_id"
                >
                  <option value selected>Select Method</option>
                  <option
                    v-for="method in methods"
                    v-bind:value="method.id"
                    v-bind:key="method.id"
                  >{{ method.name}}</option>
                </select>
              </div>

              <div class="form-group">
                <label for="expected_date">
                  Expected Date
                  <code>*</code>
                </label>
                <input
                  disabled
                  v-model="training.expected_date"
                  type="date"
                  class="form-control bg-white"
                  id="expected_date"
                  placeholder="Enter Expected Date"
                />
              </div>

              <div class="form-group">
                <label for="expected_cost">
                  Expected Cost
                  <code>*</code>
                </label>
                <input
                  disabled
                  v-model="training.expected_cost_formatted"
                  type="text"
                  class="form-control bg-white"
                  id="expected_cost"
                  placeholder="Enter Expected cost"
                />
              </div>

              <div class="form-group">
                <label for="actual_date">Actual Date</label>
                <input
                  disabled
                  v-model="training.actual_date"
                  type="date"
                  class="form-control bg-white"
                  id="actual_date"
                  placeholder="Enter Actual Date"
                />
              </div>

              <div class="form-group">
                <label for="actual_cost">Actual Cost</label>
                <input
                  disabled
                  v-model="training.actual_cost_formatted"
                  type="text"
                  class="form-control bg-white"
                  id="actual_cost"
                />
              </div>

              <div class="form-group">
                <label for="comment">Comment</label>
                <textarea
                  disabled
                  id="comment"
                  v-model="training.comment"
                  class="form-control bg-white"
                  placeholder="Enter Comment"
                ></textarea>
              </div>
              <div class="form-check mb-4">
                <input
                  v-model="training.completed"
                  id="completed"
                  type="checkbox"
                  class="form-check-input bg-white"
                  disabled
                />
                <label class="form-check-label" for="completed">Completed</label>
              </div>
              <router-link v-bind:to="getEditRoute()" class="btn btn-warning btn-sm">
                <i class="fas fa-pencil-alt"></i>
                Edit
              </router-link>
              <router-link to="/training" class="btn btn-danger btn-sm">Cancel</router-link>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getEditRoute() {
      return "/training/" + this.$route.params.id + "/edit";
    },
    createTraining() {
      console.log(this.training);
      this.$router.push({ name: "training.index" });
    },
    loadCurrentTraining() {
      axios({
        method: "get",
        url: "/api/trainings/" + this.$route.params.id
      })
        .then(response => {
          this.training = response.data.data;
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    loadUsers() {
      axios({
        method: "get",
        url: "/api/users"
      }).then(
        response => {
          this.users = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadMethods() {
      axios({
        method: "get",
        url: "/api/methods"
      }).then(
        response => {
          this.methods = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },

  data() {
    return {
      users: [],
      training: {},
      methods: []
    };
  },
  mounted() {
    // TODO make performance more fast
    this.loadUsers();
    this.loadMethods();
    this.loadCurrentTraining();
  }
};
</script>
