export default class Gate {
    constructor(user) {
        this.user = user;
    }

    isMaster() {
        return parseInt(this.user.type_id) == 1;
    }

    isAdmin() {
        return parseInt(this.user.type_id) == 2;
    }

    isUser() {
        return parseInt(this.user.type_id) == 3;
    }

    getCompanyId() {
        if (parseInt(this.user.managed_companies) == 1) {
            return this.user.managed_company_id;

        }

        return this.user.managed_company;
    }

    IsAdminForOneCompany() {
        return parseInt(this.user.managed_companies_count) == 1;
    }

    getCompaniesCount() {
        return this.user.managed_companies_count;
    }


    getManagedCompanyId() {
        return this.user.managed_company_id;
    }


}
