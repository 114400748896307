<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Training</h3>

            <div class="card-tools">
              <router-link to="/training/create" class="btn btn-sm btn-success">
                <i class="fas fa-plus-circle"></i> New
              </router-link>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover stripe">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th class="text-center" scope="col">Topic</th>
                  <th class="text-center" scope="col">Method</th>
                  <th class="text-center" scope="col">Expected Date</th>
                  <th class="text-center" scope="col">Actual Date</th>
                  <th class="text-center" scope="col">Completed</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="training in trainings" v-bind:key="training.id">
                  <td>{{ training.user.name }}</td>
                  <td class="text-center">{{ training.topic }}</td>
                  <td class="text-center">{{ training.method.name }}</td>
                  <td class="text-center">{{ training.expected_date }}</td>
                  <td class="text-center">{{ (training.actual_date)?training.actual_date:'N/A' }}</td>
                  <td class="text-center">{{ (training.completed)? 'Yes':'No' }}</td>

                  <td class="text-center">
                    <router-link
                      v-bind:to="getEditRoute(training.id)"
                      class="btn btn-sm btn-warning"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </router-link>
                    <router-link v-bind:to="getShowRoute(training.id)" class="btn btn-sm btn-info">
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <button class="btn btn-sm btn-danger" @click="deleteTraining(training)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import dataTables from "datatables";

export default {
  methods: {
    deleteTraining(training) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/trainings/" + training.id)
            .then(response => {
              this.trainings.splice(this.trainings.indexOf(training), 1);
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    getShowRoute(id) {
      return "/training/" + id + "/show";
    },
    getEditRoute(id) {
      return "/training/" + id + "/edit";
    },
    loadTrainings(change = false) {
      axios({
        method: "get",
        url: "/api/trainings"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }
          this.trainings = response.data.data;
          setTimeout(() => {
            this.table = $(".table").DataTable({
              dom: "Blfrtip",
              retrieve: true,
              pageLength: 20,
              order: [],
              columnDefs: [
                {
                  targets: 6,
                  orderable: false
                }
              ],
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5]
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5]
                  }
                }
              ],
              initComplete: function() {
                this.api()
                  .columns([0])
                  .every(function() {
                    var column = this;
                    var select = $(
                      '<select><option value="">All Users</option></select>'
                    )
                      .appendTo($(column.header()).empty())
                      .on("change", function() {
                        var val = $.fn.dataTable.util.escapeRegex(
                          $(this).val()
                        );

                        column
                          .search(val ? "^" + val + "$" : "", true, false)
                          .draw();
                      });

                    column
                      .data()
                      .unique()
                      .sort()
                      .each(function(d, j) {
                        select.append(
                          '<option value="' + d + '">' + d + "</option>"
                        );
                      });
                  });
              }
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    }
  },

  data() {
    return {
      trainings: [],
      table: ""
    };
  },
  mounted() {
    this.loadTrainings();
  }
};
</script>
