<template>
    <div class="row">
        <div class="col-md-12">
            <section class="content">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Edit Report</h3>
                        <div class="card-tools"></div>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent>
                            <div class="form-row">
                                <div class="form-group col-sm-6 row">
                                    <label class="col-sm-2" for="user_id">User</label>
                                    <div class="col-sm-10">
                                        <span>{{ evaluation.user}}</span>
                                    </div>
                                </div>
                                <div
                                    class="form-group col-sm-6 row"
                                    v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount()>1)"
                                >
                                    <label class="col-sm-2" for="company_id">Company</label>
                                    <div class="col-sm-10">
                                        <span>{{ evaluation.company}}</span>
                                    </div>
                                    <has-error :form="evaluation" field="company_id"></has-error>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label" for="date">
                                    Date
                                    <code>*</code>
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        :class="{ 'is-invalid': evaluation.errors.has('date') }"
                                        v-model="evaluation.date"
                                        type="date"
                                        class="form-control"
                                        id="date"
                                        placeholder="Enter Date"
                                    />
                                    <has-error :form="evaluation" field="date"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6" v-if="evaluation.report.businessCompetencies.length">
                                    <p>
                                        <strong>Business Competencies</strong>
                                    </p>
                                    <table class="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Competency</th>
                                            <th scope="col">Current value</th>
                                            <th scope="col">Standard value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            v-for="(competency,index) in evaluation.report.businessCompetencies"
                                            v-bind:key="index"
                                        >
                                            <td>
                                                <label
                                                    v-bind:for="competency.competency.name"
                                                >{{competency.competency.name }}</label>
                                            </td>

                                            <td style="width:30%">
                                                <input
                                                    @keyup="$inputIdentifyColorAndFeedBack($event)"
                                                    @input="$inputIdentifyColorAndFeedBack($event)"
                                                    @click="$inputIdentifyColorAndFeedBack($event)"
                                                    v-model="evaluation.skills[competency.id]['calculated_value']"
                                                    type="number"
                                                    class="form-control bg-white auto-lick-to-fire-event"
                                                    v-bind:id="evaluation.report.businessCompetencies[competency.name]"
                                                />
                                            </td>
                                            <td style="width:30%">
                                                <input
                                                    v-model="evaluation.report.businessCompetencies[index]['standard_value']"
                                                    type="number"
                                                    class="form-control bg-white"
                                                    v-bind:id="evaluation.report.businessCompetencies[competency.name]"
                                                    readonly
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6" v-if="evaluation.report.technicalCompetencies.length">
                                    <p>
                                        <strong>Technical Competencies</strong>
                                    </p>

                                    <table class="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Competency</th>
                                            <th scope="col">Current value</th>
                                            <th scope="col">Standard value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            v-for="(competency,index) in evaluation.report.technicalCompetencies"
                                            v-bind:key="index"
                                        >
                                            <td>
                                                <label v-bind:for="competency.competency.name">
                                                    {{
                                                    competency.competency.name }}
                                                </label>
                                            </td>

                                            <td style="width:30%">
                                                <input
                                                    @keyup="$inputIdentifyColorAndFeedBack($event)"
                                                    @input="$inputIdentifyColorAndFeedBack($event)"
                                                    @click="$inputIdentifyColorAndFeedBack($event)"
                                                    v-model="evaluation.skills[competency.id]['calculated_value']"
                                                    type="number"
                                                    class="form-control bg-white auto-lick-to-fire-event"
                                                    v-bind:id="evaluation.report.technicalCompetencies[competency.name]"
                                                />
                                            </td>
                                            <td style="width:30%">
                                                <input
                                                    v-model="evaluation.report.technicalCompetencies[index]['standard_value']"
                                                    type="number"
                                                    class="form-control bg-white"
                                                    v-bind:id="evaluation.report.technicalCompetencies[competency.name]"
                                                    readonly
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-10">
                                    <button
                                        v-on:click="editEvaluation"
                                        type="submit"
                                        class="btn btn-primary"
                                    >Save
                                    </button>
                                    <router-link to="/reports" class="btn btn-danger">Cancel</router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<style scoped>
    .col-form-label {
        white-space: nowrap;
    }
</style>
<script>
    export default {
        methods: {
            editEvaluation() {
                if (this.checkValidity()) {
                    this.$Progress.start();
                    this.evaluation
                        .put("/api/reports/" + this.evaluation.id)
                        .then(response => {
                            Toast.fire({
                                icon: "success",
                                title: response.data.message
                            });
                            this.$Progress.finish();
                            this.$router.push({name: "reports.index"});
                        })
                        .catch(error => {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message
                            });
                            this.$Progress.fail();
                        });
                }
            },


            checkValidity() {
                // check the values of calculated value between 0 and 100
                for (let key in this.evaluation.skills) {
                    if (this.evaluation.skills.hasOwnProperty(key)) {
                        let calculatedValue = parseInt(
                            this.evaluation.skills[key]["calculated_value"]
                        );
                        this.evaluation.skills[key]["calculated_value"] = calculatedValue;

                        if (!calculatedValue) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Calculated value is required"
                            });

                            return false;
                        }

                        if (calculatedValue > 100 || calculatedValue <= 0) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Calculated value must be between 1 and 100"
                            });

                            return false;
                        }
                    }
                }

                // individual
                // remove data of teams if exists
                if (this.evaluation.type == true && !this.evaluation.user_id) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "User is required"
                    });

                    return false;
                }

                return true;
            },
            getDataForEditing() {
                axios({
                    method: "get",
                    url: "/api/reports/" + this.$route.params.id + "/edit"
                })
                    .then(response => {
                        this.users = response.data.data.users;
                        this.companies = response.data.data.companies;
                        this.evaluation = new Form(response.data.data.report);
                        this.evaluation.skills = {};
                        // to show the value indeicator
                        setTimeout(_=> {
                            $(".auto-lick-to-fire-event").trigger("click");
                        }, 100);
                    })
                    .catch(error => {
                        console.log(error.response);
                    });
            },
        },

        data() {
            return {
                users: [],
                companies: [],
                evaluation: new Form({
                    user_id: "",
                    company_id: "",
                    report: {
                        competencies: {},
                        technicalCompetencies: [],
                        businessCompetencies: []
                    },
                    skills: {}, // Id of the user competency report => {calculated_value:50,competency:Object,competency_id:2,created_at:"2019-11-26 13:36:55",id:10,report_user_id:4,standard_value:70,updated_at:"2019-11-26 13:36:55"}
                    date: "",
                    type: true
                })
            };
        },
        mounted() {
            this.getDataForEditing();
        },
        watch: {
            "evaluation.report.competencies": {
                handler(value) {
                    // append to data that sent to backend
                    value.forEach(competencyObject => {
                        this.evaluation.skills[competencyObject.id] = competencyObject;
                    });
                },
                deep: true
            }
        }
    };
</script>
