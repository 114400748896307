<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Teams</h3>

            <div class="card-tools">
              <router-link
                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount())"
                to="/team/create"
                class="btn btn-sm btn-success"
              >
                <i class="fas fa-plus-circle"></i> New
              </router-link>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover stripe">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th
                    scope="col"
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                  >Company</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="team in teams" v-bind:key="team.id">
                  <td>{{ team.name }}</td>
                  <td
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                  >{{ team.company.name }}</td>
                  <td class="text-center">
                    <router-link v-bind:to="getEditRoute(team.id)" class="btn btn-sm btn-warning">
                      <i class="fas fa-pencil-alt"></i> Edit
                    </router-link>
                    <button class="btn btn-sm btn-danger" @click="deleteTeam(team)">
                      <i class="fas fa-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import dataTables from "datatables";

export default {
  methods: {
    deleteTeam(team) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/teams/" + team.id)
            .then(response => {
              this.loadTeams(true);
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    loadTeams(change = false) {
      axios({
        method: "get",
        url: "/api/teams"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }
          this.teams = response.data.data;
          setTimeout(() => {
            let tableColumns = [0, 1];
            if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
              tableColumns = [0];
            }

            this.table = $(".table").DataTable({
              dom: "Blfrtip",
              retrieve: true,
              pageLength: 20,
              columnDefs: [
                {
                  targets: tableColumns[tableColumns.length - 1] + 1,
                  orderable: false
                }
              ],
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: tableColumns
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: tableColumns
                  }
                }
              ]
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    },
    getEditRoute(id) {
      return "/team/" + id + "/edit";
    }
  },

  data() {
    return {
      teams: [],
      table: ""
    };
  },
  mounted() {
    this.loadTeams();
  }
};
</script>
