<template>
  <div class="row">
    <div class="col-md-12">
      <not-found v-if="!$gate.isMaster()"></not-found>
      <section class="content" v-if="$gate.isMaster()">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Create New Company</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body">
            <form v-on:submit.prevent>
              <div class="form-group">
                <label for="name">
                  Name
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': company.errors.has('name') }"
                  v-model="company.name"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Enter Name"
                />
                <has-error :form="company" field="name"></has-error>
              </div>

              <div class="form-group">
                <nav aria-label="breadcrumb" v-if="sectorsSelected.length">
                  <ol class="breadcrumb">
                    <li
                      class="breadcrumb-item"
                      v-for="item in sectorsSelected"
                      v-bind:key="item"
                    >{{ item }}</li>
                  </ol>
                </nav>
                <tree :data="treeData" :options="treeOptions" v-model="selectedNode">
                  <div class="tree-scope" slot-scope="{ node }">
                    <template v-if="node.data.isRoot">
                      <!-- you can add an icon here, but it is too long for the demo... sorry  -->

                      <span class="text">{{ node.text }}</span>

                      <span class="release" v-if="!node.hasChildren()">{{ node.data.release }}</span>
                    </template>
                    <template v-else>
                      <!-- here we can leave a vue-router for example... -->

                      <span class="text">{{ node.text }}</span>
                    </template>
                  </div>
                </tree>
              </div>
              <div class="form-group">
                <label for="currency_id">Currency</label>
                <select
                  :class="{ 'is-invalid': company.errors.has('currency_id') }"
                  v-model="company.currency_id"
                  class="form-control"
                  id="currency_id"
                >
                  <option value selected>Select Currency</option>
                  <option
                    v-for="currency in currencies"
                    v-bind:value="currency.id"
                    v-bind:key="currency.id"
                  >
                    {{ currency.name
                    }}
                  </option>
                </select>
                <has-error :form="company" field="currency_id"></has-error>
              </div>
              <div class="form-group">
                <label for="logo">Logo</label>
                <input
                  :class="{ 'is-invalid': company.errors.has('logo') }"
                  accept="image/*"
                  @change="uploadImage($event)"
                  type="file"
                  class="form-control"
                  id="logo"
                  style="height: unset !important;"
                />
                <img
                  alt="logo"
                  v-if="previewImage"
                  v-bind:src="previewImage"
                  class="uploading-image"
                />
                <has-error :form="company" field="logo"></has-error>
              </div>
              <div class="form-group">
                <label for="country_id">Country</label>
                <select
                  :class="{ 'is-invalid': company.errors.has('country_id') }"
                  v-model="company.country_id"
                  class="form-control"
                  id="country_id"
                >
                  <option value selected>Select Country</option>
                  <option
                    v-for="country in countries"
                    v-bind:value="country.id"
                    v-bind:key="country.id"
                  >
                    {{ country.name
                    }}
                  </option>
                </select>
                <has-error :form="company" field="country_id"></has-error>
              </div>
              <div class="form-group" v-if="false">
                <label for="admin_id">
                  Admin Email
                  <code>*</code>
                </label>

                <select
                  :class="{ 'is-invalid': company.errors.has('admin_id') }"
                  v-model="company.admin_id"
                  class="form-control"
                  id="admin_id"
                >
                  <option value="1" selected>Select Admin</option>
                </select>
                <has-error :form="company" field="admin_id"></has-error>
              </div>

              <button v-on:click="createCompany" type="submit" class="btn  btn-primary">Save</button>
              <router-link to="/companies" class="btn  btn-danger">Cancel</router-link>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>


<style>
.uploading-image {
  display: flex;
  width: 70px;
  height: 70px;
}
</style>
<script>
export default {
  methods: {
    checkValidity() {
      if (!this.company.name) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      if (!this.company.admin_id) {
        this.company.admin_id = 1;
      }

      return true;
    },
    uploadImage(e) {
      const image = e.target.files[0];
      this.company.logo = image;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = e => {
        this.previewImage = e.target.result;
      };
    },
    setValuesForTree() {
      let node = this.selectedNode;
      this.company["sector_id"] = "";
      this.company["sub_sector_id"] = "";
      this.company["segment_id"] = "";
      this.company["sub_segment_id"] = "";
      while (node) {
        if (node.data.id) {
          this.company[node.data.field_for_company] = node.data.id;
        }

        node = node.parent;
      }
    },
    createCompany() {
      if (this.checkValidity()) {
        this.setValuesForTree();
        this.$Progress.start();
        this.company
          .post("/api/companies", {
            // Transform form data to FormData
            transformRequest: [
              function(data, headers) {
                return objectToFormData(data);
              }
            ],
            onUploadProgress: e => {
              // Do whatever you want with the progress event
              // console.log(e);
            }
          })
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            this.$router.push({ name: "companies.index" });
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    loadCountries() {
      axios({
        method: "get",
        url: "/api/countries"
      }).then(
        response => {
          this.countries = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadCurrencies() {
      axios({
        method: "get",
        url: "/api/currencies"
      }).then(
        response => {
          this.currencies = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadUsers() {
      axios({
        method: "get",
        url: "/api/users"
      }).then(
        response => {
          this.users = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },

  },

  name: "imageUpload",

  data() {
    return {
      previewImage: null,
      users: [],
      currencies: [],
      countries: [],
      company: new Form({
        name: "",
        currency_id: "",
        logo: "",
        country_id: "",
        admin_id: "",
        segment_id: "",
        sub_segment_id: "",
        sub_sector_id: "",
        sector_id: ""
      }),
      sectorsSelected: [],
      selectedNode: null,
      treeOptions: {
        multiple: false
      },
      treeData: new Promise(resolve => {
        axios({
          method: "get",
          url: "/api/tree-sectors"
        }).then(
          response => {
            resolve({
              text: "Choose Sector",
              state: { expanded: false },
              children: response.data.data
            });
          },
          error => {
            console.log(error);
          }
        );
      })
    };
  },
  mounted() {},
  created() {
    if (this.$gate.isMaster()) {
      // this.loadTreeSector();
      this.loadCountries();
      this.loadCurrencies();
      this.loadUsers();
    }
  },
  watch: {
    selectedNode: function(val) {
      let node = val;
      this.sectorsSelected = [];
      while (node.data.id) {
        this.sectorsSelected.push(node.data.text);
        node = node.parent;
      }
      this.sectorsSelected.reverse();
    }
  }
};
</script>
