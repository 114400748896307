<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Reports</h3>

            <div class="card-tools">
              <router-link
                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount())"
                to="/reports/create"
                class="btn btn-sm btn-success"
              >
                <i class="fas fa-plus-circle"></i> New
              </router-link>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover stripe">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col" class="text-center">Role</th>
                  <th scope="col" class="text-center">Job title</th>

                  <th scope="col">Name</th>
                  <th
                    class="text-center"
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                    scope="col"
                  >Company</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="report in reports" v-bind:key="report.id">
                  <td
                    class="link-to-show-here"
                    @click="goToPath(getShowRoute(report.id,(report.user)? 1 : 0))"
                  >{{ report.date }}</td>
                    <td
                        class="link-to-show-here text-center"
                        @click="goToPath(getShowRoute(report.id,(report.user)? 1 : 0))"
                    >{{ (report.user)? report.role : 'Team' }}</td>
                  <td
                    class="link-to-show-here text-center"
                    @click="goToPath(getShowRoute(report.id,(report.user)? 1 : 0))"
                  >{{ (report.user&&report.jobTitle)? report.jobTitle : 'Team' }}</td>
                  <td
                    class="link-to-show-here"
                    @click="goToPath(getShowRoute(report.id,(report.user)? 1 : 0))"
                  >{{ (report.user)?report.user:report.team }}</td>
                  <td
                    class="link-to-show-here text-center"
                    @click="goToPath(getShowRoute(report.id,(report.user)? 1 : 0))"
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                  >{{ (report.user)?report.company :report.company }}</td>
                  <td class="text-center">
                    <router-link
                      v-if="report.user"
                      v-bind:to="getEditRoute(report.id)"
                      class="btn btn-sm btn-warning"
                    >
                      <i class="fas fa-pencil-alt"></i> Edit
                    </router-link>
                    <button
                      class="btn btn-sm btn-danger"
                      @click="deleteReport(report.id,(report.user)?true:false)"
                    >
                      <i class="fas fa-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
.link-to-show-here {
  cursor: pointer;
}
</style>
<script>
import dataTables from "datatables";

export default {
  methods: {
    goToPath(path) {
      this.$router.push(path);
    },
    deleteReport(id, type) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/reports/" + id, {
              params: {
                type: type
              }
            })
            .then(response => {
              this.loadReports(true); // Important
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    loadReports(change = false) {
      axios({
        method: "get",
        url: "/api/reports"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }
          this.reports = response.data.data;
          setTimeout(() => {
            let tableColumns = [0, 1, 2, 3];
            if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
              tableColumns = [0, 1, 2];
            }
            this.table = $(".table").DataTable({
              dom: "Blfrtip",
              retrieve: true,
              pageLength: 20,
              order: [[0, "desc"]],
              columnDefs: [
                {
                  targets: tableColumns[tableColumns.length - 1] + 1,
                  orderable: false
                }
              ],
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: tableColumns
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: tableColumns
                  }
                }
              ]
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    },
    getShowRoute(id, type) {
      return "/reports/" + id + "/show" + "?type=" + type;
    },
    getEditRoute(id) {
      return "/reports/" + id + "/edit";
    }
  },

  data() {
    return {
      reports: [],
      table: ""
    };
  },
  mounted() {
    this.loadReports();
  }
};
</script>
