<template>
  <div class="row">
    <div class="col-md-12">
      <not-found v-if="!$gate.isMaster()"></not-found>

      <section class="content" v-if="$gate.isMaster()">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Company Details</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body box-profile">
            <div class="text-center">
              <img
                v-if="previewImage"
                class="uploading-image profile-user-img img-fluid img-circle mb-4"
                v-bind:src="previewImage"
                alt="User profile picture"
              />
            </div>
            <ul class="list-group list-group-unbordered mb-3">
              <li class="list-group-item">
                <b class="col-sm-2">Name</b>
                {{company.name}}
              </li>
              <li class="list-group-item">
                <nav aria-label="breadcrumb" v-if="sectorsSelected.length">
                  <ol class="breadcrumb">
                    <li
                      class="breadcrumb-item"
                      v-for="item in sectorsSelected"
                      v-bind:key="item"
                    >{{ item }}</li>
                  </ol>
                </nav>
                <tree
                  v-show="false"
                  ref="tree"
                  :data="treeData"
                  :options="treeOptions"
                  v-model="selectedNode"
                >
                  <div class="tree-scope" slot-scope="{ node }">
                    <template v-if="node.data.isRoot">
                      <!-- you can add an icon here, but it is too long for the demo... sorry  -->

                      <span class="text">{{ node.text }}</span>

                      <span class="release" v-if="!node.hasChildren()">{{ node.data.release }}</span>
                    </template>
                    <template v-else>
                      <!-- here we can leave a vue-router for example... -->

                      <span class="text">{{ node.text }}</span>
                    </template>
                  </div>
                </tree>
              </li>
              <li class="list-group-item">
                <b class="col-sm-2">Country</b>
                {{ company.country}}
              </li>
              <li class="list-group-item">
                <b class="col-sm-2">Currency</b>
                {{company.currency}}
              </li>
              <li class="list-group-item">
                <b class="col-sm-2">Admin</b>
                {{ company.admin.email
                }}
              </li>
              <li class="list-group-item">
                <router-link v-bind:to="getEditRoute(company.id)" class="btn btn-sm btn-warning">
                  <i class="fas fa-pencil-alt"></i> Edit
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.uploading-image {
  display: flex;
  width: 70px;
  height: 70px;
}
</style>
<script>
export default {
  methods: {
    setValuesForTree() {
      let node = this.selectedNode;
      this.company["sector_id"] = "";
      this.company["sub_sector_id"] = "";
      this.company["segment_id"] = "";
      this.company["sub_segment_id"] = "";
      while (node) {
        if (node.data.id) {
          this.company[node.data.field_for_company] = node.data.id;
        }

        node = node.parent;
      }
    },

    uploadImage(e) {
      const image = e.target.files[0];
      this.company.logo = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
      };
    },
    getEditRoute(id) {
      return "/companies/" + id + "/edit";
    },
    loadCurrentCompany() {
      axios({
        method: "get",
        url: "/api/companies/" + this.$route.params.id
      })
        .then(response => {
          this.company = new Form(response.data.data);
          this.previewImage = this.company.logo;
          this.company.logo = "";
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    loadTreeSector() {
      axios({
        method: "get",
        url: "/api/tree-sectors"
      }).then(
        response => {
          this.treeData = {
            text: "Choose Sector",
            state: { expanded: false },
            children: response.data.data
          };
        },
        error => {
          console.log(error);
        }
      );
    },
    expendSectorsTree() {
      let selection = this.$refs.tree.findAll({
        state: { disabled: false }
      });
      selection.expand(true);
    }
  },
  data() {
    return {
      sectorsSelected: [],
      previewImage: null,
      users: [],
      currencies: [],
      countries: [],
      treeOfSectors: ["sub_segment", "segment", "sub_sector", "sector"], // order is important
      company: new Form({
        id: 1,
        name: "",
        currency_id: "",
        logo: "",
        country_id: "",
        admin_id: 1,
        segment_id: "",
        sub_segment_id: "",
        sub_sector_id: "",
        sector_id: "",
        selected_node_text: "",
        _method: "put",
        admin: {
          email: ""
        }
      }),
      selectedNode: null,
      treeOptions: {
        multiple: false
      },
      treeData: new Promise(resolve => {
        axios({
          method: "get",
          url: "/api/tree-sectors"
        }).then(
          response => {
            resolve({
              text: "Sectors",
              state: { expanded: true },
              children: response.data.data
            });
          },
          error => {
            console.log(error);
          }
        );
      })
    };
  },
  mounted() {
    if (this.$gate.isMaster()) {
      this.loadCurrentCompany();
    }
  },
  watch: {
    selectedNode: function(val) {
      let node = val;
      this.sectorsSelected = [];
      while (node.data.id) {
        this.sectorsSelected.push(node.data.text);
        node = node.parent;
      }
      this.sectorsSelected.reverse();
    },
    company: function(val) {
      this.expendSectorsTree();
      let hasValuse = false;

      if (this.company.selected_node_text) {
        let selection = this.$refs.tree.findAll({
          text: this.company.selected_node_text.split("|")[0],
          state: { disabled: false }
        });
        selection = selection.filter(
          node => node.data.id == this.company.selected_node_text.split("|")[1]
        );
        if (selection[0] && !this.selectedNode) {
          selection[0].select();
        }
      }
    }
  }
};
</script>
