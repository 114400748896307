<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Edit Training</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body">
            <form v-on:submit.prevent>
              <div class="form-group">
                <label for="user_id">
                  User
                  <code>*</code>
                </label>
                <select
                  :class="{ 'is-invalid': training.errors.has('user_id') }"
                  v-model="training.user_id"
                  class="form-control"
                  id="user_id"
                >
                  <option value selected>Select User</option>
                  <option
                    v-for="user in users"
                    v-bind:value="user.id"
                    v-bind:key="user.id"
                  >{{ user.name}}</option>
                </select>
                <has-error :form="training" field="user_id"></has-error>
              </div>

              <div class="form-group">
                <label for="topic">
                  Topic
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': training.errors.has('topic') }"
                  v-model="training.topic"
                  type="text"
                  class="form-control"
                  id="topic"
                  placeholder="Enter Topic"
                />
                <has-error :form="training" field="topic"></has-error>
              </div>

              <div class="form-group">
                <label for="method_id">
                  Method
                  <code>*</code>
                </label>
                <select v-model="training.method_id" class="form-control" id="method_id">
                  <option value selected>Select Method</option>
                  <option
                    :class="{ 'is-invalid': training.errors.has('method_id') }"
                    v-for="method in methods"
                    v-bind:value="method.id"
                    v-bind:key="method.id"
                  >{{ method.name}}</option>
                </select>
                <has-error :form="training" field="method_id"></has-error>
              </div>
              <div class="form-group">
                <label for="expected_date">
                  Expected Date
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': training.errors.has('expected_date') }"
                  v-model="training.expected_date"
                  type="date"
                  class="form-control"
                  id="expected_date"
                  placeholder="Enter Expected Date"
                />
                <has-error :form="training" field="expected_date"></has-error>
              </div>

              <div class="form-group">
                <label for="expected_cost">
                  Expected Cost
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': training.errors.has('expected_cost') }"
                  v-model="training.expected_cost"
                  type="number"
                  class="form-control"
                  id="expected_cost"
                  placeholder="Enter Expected cost"
                />
                <has-error :form="training" field="expected_cost"></has-error>
              </div>

              <div class="form-group">
                <label for="actual_date">Actual Date</label>
                <input
                  :class="{ 'is-invalid': training.errors.has('actual_date') }"
                  v-model="training.actual_date"
                  type="date"
                  class="form-control"
                  id="actual_date"
                  placeholder="Enter Actual Date"
                />
                <has-error :form="training" field="actual_date"></has-error>
              </div>

              <div class="form-group">
                <label for="actual_cost">Actual Cost</label>
                <input
                  :class="{ 'is-invalid': training.errors.has('actual_cost') }"
                  v-model="training.actual_cost"
                  type="number"
                  class="form-control"
                  id="actual_cost"
                  placeholder="Enter Actual cost"
                />
                <has-error :form="training" field="actual_cost"></has-error>
              </div>

              <div class="form-group">
                <label for="comment">Comment</label>
                <textarea
                  :class="{ 'is-invalid': training.errors.has('comment') }"
                  id="comment"
                  v-model="training.comment"
                  class="form-control"
                  placeholder="Enter Comment"
                ></textarea>
                <has-error :form="training" field="comment"></has-error>
              </div>
              <div class="form-check mb-4">
                <input
                  :class="{ 'is-invalid': training.errors.has('completed') }"
                  v-model="training.completed"
                  id="completed"
                  type="checkbox"
                  class="form-check-input"
                />
                <label class="form-check-label" for="completed">Completed</label>
                <has-error :form="training" field="completed"></has-error>
              </div>

              <button v-on:click="createTraining" type="submit" class="btn btn-primary">Save</button>
              <router-link to="/training" class="btn btn-danger">Cancel</router-link>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    checkValidity() {
      if (!this.training.user_id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "User is required"
        });

        return false;
      }

      if (!this.training.topic) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Topic is required"
        });

        return false;
      }

      if (!this.training.method_id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Method is required"
        });

        return false;
      }
      if (!this.training.expected_date) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Expected Date is required"
        });
        return false;
      }

      if (!this.training.expected_cost) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Expected Cost is required"
        });
        return false;
      }

      return true;
    },
    createTraining() {
      if (this.checkValidity()) {
        this.$Progress.start();
        this.training
          .put("/api/trainings/" + this.training.id)
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            this.$router.push({ name: "training.index" });
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    loadCurrentTraining() {
      axios({
        method: "get",
        url: "/api/trainings/" + this.$route.params.id
      })
        .then(response => {
          this.training = new Form(response.data.data);
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    loadUsers() {
      axios({
        method: "get",
        url: "/api/users"
      }).then(
        response => {
          this.users = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadMethods() {
      axios({
        method: "get",
        url: "/api/methods"
      }).then(
        response => {
          this.methods = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },

  data() {
    return {
      users: [],

      training: new Form({
        user_id: 1,
        method_id: 1,
        topic: "",
        completed: null,
        comment: "",
        expected_date: "",
        expected_cost: "",
        actual_date: "",
        actual_cost: ""
      }),
      methods: []
    };
  },
  mounted() {
    this.loadUsers();
    this.loadMethods();
    this.loadCurrentTraining();
  }
};
</script>
