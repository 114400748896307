<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Roles And Competencies</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Role</th>
                  <th scope="col">Competencies</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="roleCompetency in roleCompetencies" v-bind:key="roleCompetency.id">
                  <td>{{ roleCompetency.name }}</td>
                  <td>
                    <table class="table-hover w-100">
                      <tbody>
                        <tr
                          v-for="competency in roleCompetency.competencies"
                          v-bind:key="competency.id"
                        >
                          <th scope="row">{{ competency['name'] }}</th>
                          <td class="text-right">{{ competency['standard_value'] }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <button
                      @click="editRoleCompetencies(roleCompetency)"
                      type="button"
                      class="btn btn-sm btn-warning"
                      data-toggle="modal"
                      data-target="#edit-roleCompetencies"
                    >
                      <i class="fas fa-pencil-alt"></i>
                      Edit
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      @click="deleteRoleCompetencies(roleCompetency)"
                    >
                      <i class="fas fa-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    <!-- edit roleCompetencies Modal -->
    <div
      class="modal fade"
      id="edit-roleCompetencies"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editRoleCompetenciesLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editRoleCompetenciesLabel">Edit Role Competencies</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form v-on:submit.prevent>
            <div class="modal-body">
              <ul>
                <li v-for="error in form.errors.errors" v-bind:key="error">{{ error[0] }}</li>
              </ul>
              <div class="form-group">
                <label for="edit_role_id">Role</label>
                <select v-model="form.role_id" class="form-control" id="edit_role_id">
                  <option
                    v-for="role in roles"
                    v-bind:value="role.id"
                    v-bind:key="role.id"
                  >{{role.name}}</option>
                </select>
                <has-error :form="form" field="role_id"></has-error>
              </div>

              <div
                v-for="(competency,index) in editCompetencies"
                v-bind:key="competency.id"
                class="form-group"
              >
                <label v-bind:for="competency['name']">{{ competency['name'] }}</label>
                <input
                  :placeholder="competency['name']"
                  v-model="editCompetencies[index]['standard_value']"
                  type="number"
                  class="form-control"
                />
                <div>
                  <button
                    class="btn btn-sm btn-danger mt-3"
                    @click="deleteSkillForEdit(competency)"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
                <has-error :form="form" field="skills"></has-error>
              </div>

              <div class="form-group">
                <label for="edit_competencies">Competencies</label>
                <select v-model="competency" class="form-control" id="edit_competencies">
                  <option
                    v-for="Competency in allCompetenciesFiltered"
                    v-bind:value="Competency"
                    v-bind:key="Competency.id"
                  >{{Competency.name}}</option>
                </select>
                <input
                  type="number"
                  class="form-control"
                  v-model="newValueToAdd"
                  placeholder="Enter the standard value for new competency"
                />
              </div>
              <div class="form-group">
                <button
                  v-on:click="addCompetencyForEdit()"
                  type="submit"
                  class="btn btn-sm btn-success"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                @click="updateRoleCompetencies()"
                type="button"
                class="btn btn-primary"
              >Save</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataTables from "datatables";

export default {
  methods: {
    resetData() {
      this.roleCompetenciesName = "";
      this.editCompetencies = [];
      this.form.role_id = "";
      this.competency = "";
      this.newValueToAdd = "";
    },
    deleteSkillForEdit(competency) {
      delete this.form.skills[competency.id];
      this.editCompetencies.splice(
        this.editCompetencies.indexOf(competency),
        1
      );
    },
    deleteSkillForAdd(competency) {
      delete this.form.skills[competency.competency.id];
      this.addCompetencies.splice(this.addCompetencies.indexOf(competency), 1);
    },
    checkValidity() {
      if (!this.form.role_id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Job Title is required"
        });

        return false;
      }
      if (_.isEmpty(this.form.skills)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Competencies is required"
        });

        return false;
      }

      return true;
    },
    checkAddValidity() {
      if (!this.newValueToAdd) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: " The standard value is required"
        });

        return false;
      }

      return true;
    },
    addCompetency() {
      if (this.checkAddValidity()) {
        this.addCompetencies.push({
          competency: this.allCompetencies[
            this.allCompetencies.indexOf(this.competency)
          ],
          standardValue: this.newValueToAdd
        });
        this.newValueToAdd = ""; //reset
      }
    },
    addCompetencyForEdit() {
      if (this.checkAddValidity()) {
        this.competency["standard_value"] = this.newValueToAdd;
        this.editCompetencies.push(this.competency);
        this.newValueToAdd = ""; //reset
      }
    },
    editRoleCompetencies(roleCompetencies) {
      this.competency = ""; //reset
      this.newValueToAdd = ""; //reset
      this.form.role_id = roleCompetencies.id;
      this.editCompetencies = roleCompetencies.competencies;
      this.form.skills = roleCompetencies.competencies;
      this.editRoleId = roleCompetencies.id;
    },
    updateRoleCompetencies() {
      if (this.checkValidity()) {
        this.$Progress.start();
        this.form
          .put("/api/role-competencies/" + this.editRoleId)
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            $("#edit-roleCompetencies").modal("toggle");
            this.loadRoleCompetencies(true);
            this.resetData(); // reset
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    deleteRoleCompetencies(roleCompetencies) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/roles/" + roleCompetencies.id)
            .then(response => {
              this.loadRoleCompetencies(true);
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    loadRoles() {
      axios({
        method: "get",
        url: "/api/roles"
      }).then(
        response => {
          this.roles = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadCompetencies() {
      axios({
        method: "get",
        url: "/api/competencies"
      }).then(
        response => {
          this.allCompetencies = response.data.data;
          this.allCompetenciesFiltered = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadRoleCompetencies(change = false) {
      axios({
        method: "get",
        url: "/api/role-competencies"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }
          this.roleCompetencies = response.data.data;
          setTimeout(() => {
            this.table = $(".table").DataTable({
              retrieve: true,
              dom: "Blfrtip",
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: [0, 1]
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: [0, 1]
                  }
                }
              ]
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  watch: {
    addCompetencies(newVal) {
      this.form.skills = newVal;
    }
  },
  data() {
    return {
      form: new Form({
        role_id: "",
        skills: {}
      }),
      competency: "",
      newValueToAdd: "",
      addCompetencies: [],
      editCompetencies: [],
      roleCompetencies: [],
      allCompetencies: {},
      allCompetenciesFiltered: [],
      roles: [],
      editRoleId: "",
      table: ""
    };
  },
  mounted() {
    this.loadRoles();
    this.loadCompetencies();
    this.loadRoleCompetencies();
  },
  watch: {
    editCompetencies: {
      handler(val) {
        let currentCompetenciesIds = val.map(e => {
          return e.id;
        });
        this.allCompetenciesFiltered = this.allCompetencies.filter(
          el => !currentCompetenciesIds.includes(el.id)
        );
      },
      deep: true
    }
  }
};
</script>
