<template>
  <div class="row">
    <div class="col-md-12">
      <not-found v-if="!$gate.isMaster()"></not-found>

      <section class="content" v-if="$gate.isMaster()">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Companies</h3>
            <div class="card-tools">
              <router-link to="/companies/create" class="btn btn-sm btn-success">
                <i class="fas fa-plus-circle"></i> New
              </router-link>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover stripe">
              <thead>
                <tr>
                  <th scope="col">Logo</th>
                  <th scope="col">Name</th>
                  <th scope="col" class="text-center">Country</th>
                  <th scope="col">Admin</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="company in companies" v-bind:key="company.id">
                  <td>
                    <img class="img-thumbnail" v-bind:src="company.logo" alt="logo" />
                  </td>
                  <td>{{ company.name }}</td>
                  <td class="text-center">{{ company.country }}</td>
                  <td>{{ (company.admin)?company.admin.email:'N/A' }}</td>
                  <td class="text-center">
                    <router-link
                      v-bind:to="getEditRoute(company.id)"
                      class="btn btn-sm btn-warning"
                    >
                      <i class="fas fa-pencil-alt"></i> Edit
                    </router-link>
                    <button class="btn btn-sm btn-danger" @click="deleteCompany(company)">
                      <i class="fas fa-trash"></i> Delete
                    </button>
                    <router-link v-bind:to="getShowRoute(company.id)" class="btn btn-sm btn-info">
                      <i class="fas fa-eye"></i> Show
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
img {
  height: 70px;
}
</style>
<script>
import dataTables from "datatables";

export default {
  methods: {
    deleteCompany(company) {
      Swal.fire({
        title: "Are you sure?",
        text:
          "This will delete the admin of this company also you won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/companies/" + company.id)
            .then(response => {
              this.loadCompanies(true);
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    getShowRoute(id) {
      return "/companies/" + id;
    },
    getEditRoute(id) {
      return "/companies/" + id + "/edit";
    },
    loadCompanies(change = false) {
      axios({
        method: "get",
        url: "/api/companies"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }
          this.companies = response.data.data;

          setTimeout(() => {
            this.table = $(".table").DataTable({
              dom: "Blfrtip",
              retrieve: true,
              pageLength: 20,
              order: [],
              columnDefs: [
                {
                  targets: 0,
                  orderable: false
                },
                {
                  targets: 4,
                  orderable: false
                }
              ],
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: [1, 2, 3, 4]
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: [1, 2, 3, 4]
                  }
                }
              ]
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  data() {
    return {
      companies: [],
      table: ""
    };
  },
  mounted() {},
  created() {
    if (this.$gate.isMaster()) {
      this.loadCompanies();
    }
  }
};
</script>
