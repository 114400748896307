import Dashboard from './components/Dashboard.vue';
import Companies from './components/Companies.vue';
import CreateCompanies from './components/CreateCompanies.vue';
import EditCompanies from './components/EditCompanies.vue';
import ShowCompanies from './components/ShowCompanies.vue';

import Branches from './components/Branches.vue';
import CreateBranches from './components/CreateBranches.vue';
import EditBranches from './components/EditBranches.vue';
import Team from './components/Team.vue';
import CreateTeam from './components/CreateTeam.vue';
import EditTeam from './components/EditTeam.vue';
import Users from './components/Users.vue';
import CreateUsers from './components/CreateUsers.vue';
import EditUsers from './components/EditUsers.vue';
import Reports from './components/Reports.vue';
import CreateReports from './components/CreateReports.vue';
import EditReports from './components/EditReports.vue';
import ShowReports from './components/ShowReports.vue';
import Training from './components/Training.vue';
import CreateTraining from "./components/CreateTraining";
import EditTraining from "./components/EditTraining";
import ShowTraining from "./components/ShowTraining";
import Roles from "./components/Roles";
import Currencies from "./components/Currencies";
import Competencies from "./components/Competencies";
import RolesCompetencies from "./components/RolesCompetencies";
import Search from "./components/Search";
import NotFound from "./components/NotFound";
import SectorsTree from "./components/SectorsTree";


export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {}
    },
    {
        path: '/home',
        name: 'home',
        component: Dashboard,
        meta: {}
    },
    {
        path: '/companies',
        name: 'companies.index',
        component: Companies,
        meta: {}
    },
    {
        path: '/companies/create',
        name: 'companies.create',
        component: CreateCompanies,
        meta: {}
    },
    {
        path: '/companies/:id/edit',
        name: 'companies.edit',
        component: EditCompanies,
        meta: {}
    },
    {
        path: '/companies/:id/',
        name: 'companies.show',
        component: ShowCompanies,
        meta: {}
    },
    {
        path: '/branches',
        name: 'branches.index',
        component: Branches,
        meta: {}
    },

    {
        path: '/branches/create',
        name: 'branches.create',
        component: CreateBranches,
        meta: {}
    },
    {
        path: '/branches/:id/edit',
        name: 'branches.edit',
        component: EditBranches,
        meta: {}
    },

    {
        path: '/team',
        name: 'team.index',
        component: Team,
        meta: {}
    },
    {
        path: '/team/create',
        name: 'team.create',
        component: CreateTeam,
        meta: {}
    },
    {
        path: '/team/:id/edit',
        name: 'team.edit',
        component: EditTeam,
        meta: {}
    },
    {
        path: '/users',
        name: 'users.index',
        component: Users,
        meta: {}
    },
    {
        path: '/users/create',
        name: 'users.create',
        component: CreateUsers,
        meta: {}
    },
    {
        path: '/users/:id/edit',
        name: 'users.edit',
        component: EditUsers,
        meta: {}
    },

    {
        path: '/reports',
        name: 'reports.index',
        component: Reports,
        meta: {}
    },

    {
        path: '/reports/create',
        name: 'reports.create',
        component: CreateReports,
        meta: {}
    },


    {
        path: '/reports/:id/edit',
        name: 'reports.edit',
        component: EditReports,
        meta: {}
    },
    {
        path: '/reports/:id/show',
        name: 'reports.show',
        component: ShowReports,
        meta: {}
    },
    {
        path: '/training',
        name: 'training.index',
        component: Training,
        meta: {}
    },
    {
        path: '/training/create',
        name: 'training.create',
        component: CreateTraining,
        meta: {}
    },
    {
        path: '/training/:id/edit',
        name: 'training.edit',
        component: EditTraining,
        meta: {}
    },
    {
        path: '/training/:id/show',
        name: 'training.show',
        component: ShowTraining,
        meta: {}
    },
    {
        path: '/settings/roles',
        name: 'settings.roles.index',
        component: Roles,
        meta: {}
    },
    {
        path: '/settings/currencies',
        name: 'settings.currencies.index',
        component: Currencies,
        meta: {}
    },
    {
        path: '/settings/competencies',
        name: 'settings.competencies.index',
        component: Competencies,
        meta: {}
    },
    {
        path: '/settings/roles-competencies',
        name: 'settings.roles.competencies.index',
        component: RolesCompetencies,
        meta: {}
    },
    {
        path: '/search',
        name: 'search.index',
        component: Search,
        meta: {}
    },
    {
        path: '/settings/sectors-tree',
        name: 'settings.sectors.tree',
        component: SectorsTree,
        meta: {}
    },
    {
        path: '*',
        name: 'not_found',
        component: NotFound,
        meta: {}
    },

];
