<template>
    <div class="row">
        <div class="col-md-12">
            <section class="content">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Create New User</h3>
                        <div class="card-tools"></div>
                    </div>

                    <div class="card-body">
                        <div
                            v-if="JSON.stringify(user.errors.errors).length>2"
                            class="alert alert-danger"
                            role="alert"
                        >
                            There were some problems with your input.
                            <ul>
                                <li
                                    v-for="(message,key) in user.errors.errors"
                                    v-bind:key="key"
                                >{{ key }} : {{ message[0] }}
                                </li>
                            </ul>
                        </div>
                        <form v-on:submit.prevent>
                            <div class="form-group">
                                <label for="name">
                                    Name
                                    <code>*</code>
                                </label>
                                <input
                                    :class="{ 'is-invalid': user.errors.has('name') }"
                                    v-model="user.name"
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    placeholder="Enter name"
                                />
                                <has-error :form="user" field="name"></has-error>
                            </div>
                            <div class="form-group">
                                <label for="email">
                                    Email
                                    <code>*</code>
                                </label>
                                <input
                                    :class="{ 'is-invalid': user.errors.has('email') }"
                                    v-model="user.email"
                                    type="email"
                                    class="form-control"
                                    id="email"
                                    placeholder="Enter email"
                                />
                                <has-error :form="user" field="email"></has-error>
                            </div>

                            <div
                                class="form-group"
                                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount()>1)"
                            >
                                <label for="company_id">
                                    Company
                                    <code>*</code>
                                </label>
                                <select
                                    :class="{ 'is-invalid': user.errors.has('company_id') }"
                                    v-model="user.company_id"
                                    class="form-control"
                                    id="company_id"
                                >
                                    <option value selected>Select Company</option>
                                    <option
                                        v-for="company in companies"
                                        v-bind:value="company.id"
                                        v-bind:key="company.id"
                                    >{{ company.name}}
                                    </option>
                                </select>
                                <has-error :form="user" field="company_id"></has-error>
                            </div>
                            <div class="form-group">
                                <label for="branch_id">Branch</label>
                                <select
                                    :class="{ 'is-invalid': user.errors.has('branch_id') }"
                                    v-model="user.branch_id"
                                    class="form-control"
                                    id="branch_id"
                                >
                                    <option value selected>Select Branch</option>
                                    <option
                                        v-for="branch in filteredBranches"
                                        v-bind:value="branch.id"
                                        v-bind:key="branch.id"
                                    >{{ branch.name}}
                                    </option>
                                </select>
                                <has-error :form="user" field="branch_id"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="teams">Teams</label>
                                <select
                                    v-model="user.teams"
                                    :class="{ 'is-invalid': user.errors.has('team') }"
                                    class="custom-select"
                                    id="teams"
                                    multiple
                                >
                                    <option
                                        v-for="team in filteredTeams"
                                        v-bind:value="team.id"
                                        v-bind:key="team.id"
                                    >{{ team.name }}
                                    </option>
                                </select>
                                <has-error :form="user" field="teams"></has-error>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-1">
                                    <label for="roles">
                                        Job title
                                        <code>*</code>
                                    </label>
                                </div>
                                <div class="col-sm-10">
                                    <select
                                        :class="{ 'is-invalid': user.errors.has('role') }"
                                        v-model="user.role_id"
                                        class="form-control"
                                        id="job_title"
                                    >
                                        <option
                                            v-for="(role,index) in  filteredRoles"
                                            v-bind:index="index"
                                            v-bind:value="role.id"
                                            v-bind:key="role.id"
                                        >{{ role.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-1">
                                    <label for="roles">
                                        Roles
                                        <code>*</code>
                                    </label>
                                </div>
                                <div class="col-sm-10">
                                    <select
                                        :class="{ 'is-invalid': user.errors.has('role') }"
                                        v-model="selectedRole"
                                        class="form-control"
                                        id="roles"
                                    >
                                        <option
                                            v-for="(role,index) in  filteredRoles"
                                            v-bind:index="index"
                                            v-bind:value="role"
                                            v-bind:key="role.id"
                                        >{{ role.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-1">

                                    <button v-on:click="addRole()" type="button" class="btn btn-success">
                                        <i class="fas fa-plus"></i> Add
                                    </button>
                                </div>
                            </div>

                            <div v-for="role in selectedRoles">
                                <div>
                                    <button
                                        class="btn btn-sm btn-danger"
                                        @click="removeRole(role)"
                                    >
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                    <b>{{ role.name }}</b>

                                </div>
                                <div class="form-group row">
                                    <div
                                        class="col-sm-3"
                                        v-for="(competency,index) in role.competencies"
                                        v-bind:key="index"
                                        :class="{ 'is-invalid': user.errors.has('skills') }"
                                    >
                                        <label
                                            class="col-sm-7 col-form-label"
                                            v-bind:for="competency['name']"
                                        >{{ competency['name'] }}</label>
                                        <input
                                            v-model="competency['standard_value']"
                                            @keyup="$inputIdentifyColorAndFeedBack($event)"
                                            @input="$inputIdentifyColorAndFeedBack($event)"
                                            @click="$inputIdentifyColorAndFeedBack($event)"
                                            :placeholder="competency['name']"
                                            type="number"
                                            class="col-sm-3"
                                        />
                                        <button
                                            class="btn btn-sm btn-danger ml-2 col-sm-1"
                                            @click="removeCompetency(role,competency)"
                                        >
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>

                                        <div class="col-sm-1"></div>
                                    </div>
                                </div>
                                <has-error :form="user" field="skills"></has-error>

                                <div class="form-group row">
                                    <label class="label col-sm-3">More Competencies?</label>
                                    <div class="col-sm-5">
                                        <select class="form-control" v-model="role.moreCompetency">
                                            <option
                                                v-for="Competency in role.notExistsComptencies"
                                                v-bind:key="Competency.id"
                                                v-bind:value="Competency"
                                            >{{Competency.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-sm-2">
                                        <input
                                            type="number"
                                            class="form-control"
                                            v-model="role.newValueToAdd"
                                            placeholder="Enter the standard value for new competency"
                                        />
                                    </div>
                                    <div class="col-sm-2">
                                        <button v-on:click="addCompetency(role)" type="submit" class="btn btn-success">
                                            <i class="fas fa-plus"></i> Add
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div class="form-check mb-4" v-if="$gate.isMaster()||$gate.isAdmin()">
                                <input
                                    v-model="user.isSystemUser"
                                    id="isSystemUser"
                                    type="checkbox"
                                    class="form-check-input"
                                />
                                <label class="form-check-label" for="isSystemUser">Is System User?</label>
                            </div>
                            <div class="form-group" v-if="user.isSystemUser&&($gate.isMaster()||$gate.isAdmin())">
                                <label for="type_id">
                                    Type of user system
                                    <code>*</code>
                                </label>
                                <select
                                    :class="{ 'is-invalid': user.errors.has('type_id') }"
                                    v-model="user.type_id"
                                    class="form-control"
                                    id="type_id"
                                >
                                    <option value selected>Select Type</option>
                                    <option
                                        v-for="type in systemTypes"
                                        v-bind:value="type.id"
                                        v-bind:key="type.id"
                                    >{{ type.name}}
                                    </option>
                                </select>
                                <has-error :form="user" field="type_id"></has-error>
                            </div>
                            <div class="form-group" v-if="user.isSystemUser">
                                <label for="password">
                                    Password
                                    <code>*</code>
                                </label>
                                <input
                                    :class="{ 'is-invalid': user.errors.has('password') }"
                                    v-model="user.password"
                                    type="password"
                                    class="form-control"
                                    id="password"
                                    autocomplete="off"
                                    placeholder="Enter password"
                                />
                                <has-error :form="user" field="password"></has-error>
                            </div>
                            <div class="form-group" v-if="user.isSystemUser">
                                <label for="confirmed_password">
                                    Confirm Password
                                    <code>*</code>
                                </label>
                                <input
                                    v-model="user.confirmed_password"
                                    type="password"
                                    class="form-control"
                                    id="confirmed_password"
                                    autocomplete="off"
                                    placeholder="Enter password again"
                                />
                            </div>

                            <button v-on:click="createUser" type="submit" class="btn  btn-primary">Save</button>
                            <router-link to="/users" class="btn  btn-danger">Cancel</router-link>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<style scoped>
    .col-form-label {
        white-space: nowrap;
    }

    .custom-danger {
        text-align: center;
        margin-left: 0px !important;
        padding: 4px;
    }
</style>
<script>
    export default {
        methods: {
            addRole() {
                if (!this.selectedRole) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: " you should select a role"
                    });

                    return false;
                }

                let currentCompetenciesIds = this.selectedRole.competencies.map(e => {
                    return e.id;
                });
                this.selectedRole.notExistsComptencies = this.allCompetencies.filter(
                    el => !currentCompetenciesIds.includes(el.id)
                );
                this.selectedRole.newValueToAdd = '';
                this.selectedRole.moreCompetency = '';
                this.selectedRoles.push(this.selectedRole);
                this.filteredRoles.splice(this.filteredRoles.indexOf(this.selectedRole), 1);
                this.selectedRole='';
            },
            removeRole(role){
                this.selectedRoles.splice(  this.selectedRoles.indexOf(role),1);
                this.filteredRoles.push(role)
            },
            checkAddValidity(newValueToAdd) {
                if (!newValueToAdd) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: " The standard value is required"
                    });

                    return false;
                }

                if (
                    parseInt(newValueToAdd) > 100 ||
                    parseInt(newValueToAdd) <= 0
                ) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: " The standard value must be between 1 and 100"
                    });

                    return false;
                }

                return true;
            },
            removeCompetency(role, competency) {
                role.competencies.splice(role.competencies.indexOf(competency), 1);
                role.notExistsComptencies.push(competency);

            },
            addCompetency(role) {
                role.moreCompetency["standard_value"] = parseInt(role.newValueToAdd);
                if (this.checkAddValidity(role.newValueToAdd)) {
                    role.competencies.push(role.moreCompetency);
                    role.newValueToAdd = ""; //reset
                }
                role.notExistsComptencies.splice(role.notExistsComptencies);
            },
            checkValidity() {
                if (!this.user.name) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Name is required"
                    });

                    return false;
                }

                if (!this.user.email) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Email is required"
                    });

                    return false;
                }

                if (!this.user.password) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Password is required"
                    });

                    return false;
                }

                if (!this.user.company_id) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Company is required"
                    });

                    return false;
                }

                if (!this.user.role_id) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Job Title is required"
                    });

                    return false;
                }

                if (
                    this.user.isSystemUser &&
                    this.user.password != this.user.confirmed_password
                ) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Password and confirm password not match"
                    });

                    return false;
                }

                return true;
            },
            createUser() {
                if (!this.user.isSystemUser) {
                    this.user.password = Math.random().toString(36);
                    this.user.confirmed_password = this.user.password;
                }
                if (this.checkValidity()) {
                    this.$Progress.start();
                    this.user
                        .post("/api/users")
                        .then(response => {
                            Toast.fire({
                                icon: "success",
                                title: response.data.message
                            });
                            this.$Progress.finish();
                            this.$router.push({name: "users.index"});
                        })
                        .catch(error => {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message
                            });
                            this.$Progress.fail();
                        });
                }
            },
            getDataForCreating(){
                axios({
                    method: "get",
                    url: "/api/users/create"
                }).then(
                    response => {
                        this.companies = response.data.data.companies;
                        this.branches = response.data.data.branches;
                        this.filteredBranches = response.data.data.branches;
                        this.teams = response.data.data.teams;
                        this.filteredRoles = this.roles = response.data.data.roles;
                        this.allCompetencies = response.data.data.competencies;
                        this.allCompetenciesFiltered = response.data.data.competencies;
                        if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
                            this.user.company_id = this.$gate.getManagedCompanyId();
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
            }

        },

        data() {
            return {
                selectedRoles: [],
                filteredTeams: [],
                newValueToAdd: "",
                competencies: "",
                companies: [],
                teams: [],
                roles: [],
                filteredRoles: [],
                user: new Form({
                    name: "",
                    email: "",
                    password: "",
                    confirmed_password: "",
                    company_id: "",
                    role_id: "",
                    branch_id: "",
                    teams: [],
                    isSystemUser: 0,
                    type_id: "",
                    roles: [],
                    skills:[],

                }),
                allCompetencies: [],
                branches: [],
                filteredBranches: [],
                systemTypes: [
                    {
                        name: "Admin",
                        id: 2
                    }
                ],
                selectedRole: '',
            };
        },
        mounted() {
            this.getDataForCreating()
        },
        watch: {
            "selectedRoles": {
                handler(val) {
                    this.user.skills = [];
                    val.forEach(role => {
                        role.competencies.forEach(competency => {
                            this.user.skills.push({
                                "standard_value": parseFloat(competency.standard_value),
                                "competency_id": competency.id,
                                "role_id": role.id
                            });

                        })
                    });
                },
                deep: true
            },
            "user.isSystemUser": {
                handler(val) {
                    if (val) {
                        this.user.type_id = 2;
                    } else {
                        this.user.type_id = "";
                    }
                },
                deep: true
            },
            "user.company_id": {
                handler(val) {
                    this.filteredBranches = this.branches.filter(function (branch) {
                        return parseInt(branch.company_id) === parseInt(val);
                    });
                    this.filteredTeams = this.teams.filter(team => {
                        return parseInt(team.company_id) === parseInt(val);
                    });
                },
                deep: true
            },
        }
    };
</script>
