<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Roles</h3>
            <div class="card-tools">
              <button
                type="button"
                class="btn btn-sm btn-success"
                data-toggle="modal"
                data-target="#add-role"
              ><i class="fas fa-plus-circle"></i> New</button>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="role in roles" v-bind:key="role.id">
                  <td>{{ role.name }}</td>
                  <td>
                    <button
                      @click="editRole(role)"
                      type="button"
                      class="btn btn-sm btn-warning"
                      data-toggle="modal"
                      data-target="#edit-role"
                    ><i class="fas fa-pencil-alt"></i> Edit</button>
                    <button class="btn btn-sm btn-danger" @click="deleteRole(role)"><i class="fas fa-trash"></i> Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>

    <!-- add Role Modal -->
    <div
      class="modal fade"
      id="add-role"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addRoleLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addRoleLabel">Create Job Title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form v-on:submit.prevent>
            <div class="modal-body">
              <div class="form-group">
                <label for="add-name">
                  Name
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': form.errors.has('name') }"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  id="add-name"
                  placeholder="Enter Name"
                />
                <has-error :form="form" field="name"></has-error>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="storeRole()" type="button" class="btn btn-sm btn-primary">Save</button>
              <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit Role Modal -->
    <div
      class="modal fade"
      id="edit-role"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editRoleLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editRoleLabel">Edit Job Title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form v-on:submit.prevent>
            <div class="modal-body">
              <div class="form-group">
                <label for="edit-name">
                  Name
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': role.errors.has('name') }"
                  v-model="role.name"
                  type="text"
                  class="form-control"
                  id="edit-name"
                  placeholder="Enter Name"
                />
                <has-error :form="role" field="name"></has-error>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="updateRole()" type="button" class="btn btn-sm btn-primary">Save</button>
              <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataTables from "datatables";

export default {
  methods: {
    checkAddValidity() {
      if (!this.form.name) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      return true;
    },
    checkEditValidity() {
      if (!this.role.name) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      return true;
    },
    storeRole() {
      if (this.checkAddValidity()) {
        this.$Progress.start();
        this.form
          .post("/api/roles")
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            $(".dataTables_empty").hide();

            this.loadRoles(true);
            $("#add-role").modal("toggle"); // close the modal
            // reset
            this.form.name = "";
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    editRole(role) {
      this.role = new Form(role);
    },
    updateRole() {
      if (this.checkEditValidity()) {
        this.$Progress.start();
        this.role
          .put("/api/roles/" + this.role.id)
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            $("#edit-role").modal("toggle"); // close the modal
            this.loadRoles(true);
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    deleteRole(role) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/roles/" + role.id)
            .then(response => {
              this.loadRoles(true);
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    loadRoles(change = false) {
      axios({
        method: "get",
        url: "/api/roles"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }
          this.roles = response.data.data;
          setTimeout(() => {
            this.table = $(".table").DataTable({
              retrieve: true,
              dom: "Blfrtip",
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: [0]
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: [0]
                  }
                }
              ]
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  data() {
    return {
      form: new Form({
        name: ""
      }),
      id: 2,
      role: new Form({
        id: "",
        name: ""
      }),
      roles: [],
      table: ""
    };
  },
  mounted() {
    this.loadRoles();
  }
};
</script>
