<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Users</h3>
            <div class="card-tools">
              <router-link
                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount())"
                to="/users/create"
                class="btn btn-sm btn-success"
              >
                <i class="fas fa-plus-circle"></i> New
              </router-link>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover stripe">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Email</th>

                  <th
                    scope="col"
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                  >Company</th>
                  <th scope="col">Branch</th>
                  <th scope="col">Teams</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" v-bind:key="user.id">
                  <td>{{ user.name }}</td>
                  <td>{{ user.role?user.role.name:'N/A' }}</td>

                  <td>{{ user.email }}</td>

                  <td
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                  >{{ user.company?user.company.name:'N/A' }}</td>
                  <td>{{ user.branch?user.branch.name:'N/A' }}</td>
                  <td>
                    <span v-if="!user.teams.length">N/A</span>

                    <ul v-if="user.teams">
                      <li v-for="team in user.teams" v-bind:key="team.id">{{ team.name }}</li>
                    </ul>
                  </td>

                  <td class="text-center">
                    <router-link v-bind:to="getEditRoute(user.id)" class="btn btn-sm btn-warning">
                      <i class="fas fa-pencil-alt"></i> Edit
                    </router-link>
                    <button class="btn btn-sm btn-danger" @click="deleteUser(user)">
                      <i class="fas fa-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
div.dt-buttons {
  position: relative !important;
  float: left !important;
}
</style>
<script>
import dataTables from "datatables";

export default {
  methods: {
    deleteUser(user) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/users/" + user.id)
            .then(response => {
              this.loadUsers(true);
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    loadUsers(change = false) {
      axios({
        method: "get",
        url: "/api/users"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }

          this.users = response.data.data;
          setTimeout(() => {
            let tableColumns = [0, 1, 2, 3, 4, 5];
            if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
              tableColumns = [0, 1, 2, 3, 4];
            }
            this.table = $(".table").DataTable({
              dom: "Blfrtip",
              retrieve: true,
              pageLength: 20,
              columnDefs: [
                {
                  targets: tableColumns[tableColumns.length - 1] + 1,
                  orderable: false
                }
              ],
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: tableColumns
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: tableColumns
                  }
                }
              ]
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    },
    getEditRoute(id) {
      return "/users/" + id + "/edit";
    }
  },

  data() {
    return {
      users: [],
      table: ""
    };
  },
  mounted() {
    this.loadUsers();
  }
};
</script>
