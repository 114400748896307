<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Create New Training</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <form v-on:submit.prevent>
                    <div class="form-group">
                      <label for="topic">
                        Topic
                        <code>*</code>
                      </label>
                      <input
                        :class="{ 'is-invalid': training.errors.has('topic') }"
                        v-model="training.topic"
                        type="text"
                        class="form-control"
                        id="topic"
                        placeholder="Enter Topic"
                      />
                      <has-error :form="training" field="topic"></has-error>
                    </div>

                    <div class="form-group">
                      <label for="method_id">
                        Method
                        <code>*</code>
                      </label>
                      <select
                        :class="{ 'is-invalid': training.errors.has('method_id') }"
                        v-model="training.method_id"
                        class="form-control"
                        id="method_id"
                      >
                        <option value selected>Select Method</option>
                        <option
                          v-for="method in methods"
                          v-bind:value="method.id"
                          v-bind:key="method.id"
                        >{{ method.name}}</option>
                      </select>
                      <has-error :form="training" field="method_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label for="expected_date">
                        Expected Date
                        <code>*</code>
                      </label>
                      <input
                        :class="{ 'is-invalid': training.errors.has('expected_date') }"
                        v-model="training.expected_date"
                        type="date"
                        class="form-control"
                        id="expected_date"
                        placeholder="Enter Expected Date"
                      />
                      <has-error :form="training" field="expected_date"></has-error>
                    </div>

                    <div class="form-group">
                      <label for="expected_cost">
                        Expected Cost
                        <code>*</code>
                      </label>
                      <input
                        :class="{ 'is-invalid': training.errors.has('expected_cost') }"
                        v-model="training.expected_cost"
                        type="number"
                        class="form-control"
                        id="expected_cost"
                        placeholder="Enter Expected cost"
                      />
                      <has-error :form="training" field="expected_cost"></has-error>
                    </div>

                    <div class="form-group">
                      <label for="actual_date">Actual Date</label>
                      <input
                        :class="{ 'is-invalid': training.errors.has('actual_date') }"
                        v-model="training.actual_date"
                        type="date"
                        class="form-control"
                        id="actual_date"
                        placeholder="Enter Actual Date"
                      />
                      <has-error :form="training" field="actual_date"></has-error>
                    </div>

                    <div class="form-group">
                      <label for="actual_cost">Actual Cost</label>
                      <input
                        :class="{ 'is-invalid': training.errors.has('actual_cost') }"
                        v-model="training.actual_cost"
                        type="number"
                        class="form-control"
                        id="actual_cost"
                        placeholder="Enter Actual cost"
                      />
                      <has-error :form="training" field="actual_cost"></has-error>
                    </div>

                    <div class="form-group">
                      <label for="comment">Comment</label>
                      <textarea
                        :class="{ 'is-invalid': training.errors.has('comment') }"
                        id="comment"
                        v-model="training.comment"
                        class="form-control"
                        placeholder="Enter Comment"
                      ></textarea>
                      <has-error :form="training" field="comment"></has-error>
                    </div>
                    <div class="form-check mb-4">
                      <input
                        :class="{ 'is-invalid': training.errors.has('completed') }"
                        v-model="training.completed"
                        id="completed"
                        type="checkbox"
                        class="form-check-input"
                      />
                      <label class="form-check-label" for="completed">Completed</label>
                      <has-error :form="training" field="completed"></has-error>
                    </div>

                    <button
                      v-on:click="createTraining"
                      type="submit"
                      class="btn  btn-primary"
                    >Save</button>
                    <button class="btn  btn-danger" @click="resetForm()">Reset</button>

                    <router-link to="/training" class="btn  btn-danger">Cancel</router-link>
                  </form>
                </div>
                <div class="col-sm-6" style="height:686.188px;overflow:auto">
                  <div class="wrap row">
                    <h5 class="mb-1 font-weight-bold left col-sm-4">
                      Users
                      <span class="badge badge-primary badge-pill">{{users.length}}</span>
                    </h5>

                    <h5 class="mb-1 font-weight-bold left col-sm-3">
                      Selected
                      <span class="badge badge-primary badge-pill">{{selectedUsers.length}}</span>
                    </h5>
                    <div class="col-sm-3">
                      <button
                        type="button"
                        class="btn btn-sm btn-success"
                        @click="selectAllUsers"
                      >Select All</button>
                    </div>
                    <div class="col-sm-2">
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="resetSelectUsers"
                      >Clear</button>
                    </div>
                  </div>

                  <div class="list-group list-group-flush" >
                    <a
                      v-for="user in users"
                      @click="handleSelectUser(user)"
                      v-bind:key="user.id"
                      v-bind:class="{ 'list-group-item-primary': selectedUsers.includes(user) }"
                      class="list-group-item list-group-item-action"
                    >
                      <input
                        type="checkbox"
                        :id="user.id"
                        :value="user"
                        v-model="selectedUsers"
                        v-if="false"
                      />
                      <label :for="user.name">{{user.name}}</label>
                    </a>
                  </div>
                </div>
              </div>
              <div class="row mb-4"></div>
              <div class="row mb-4" v-if="this.trainings.length">
                <div class="col-sm-12">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Topic</th>
                        <th scope="col">Method</th>
                        <th scope="col">Expected Date</th>
                        <th scope="col">Expected Cost</th>
                        <th scope="col">Actual Date</th>
                        <th scope="col">Actual Cost</th>
                        <th scope="col">Completed</th>
                        <th scope="col" class="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="training in trainings" v-bind:key="training.id">
                        <td>{{ training.user.name }}</td>
                        <td>{{ training.topic }}</td>
                        <td>{{ training.method.name }}</td>
                        <td>{{ training.expected_date }}</td>
                        <td>{{ training.expected_cost_formatted }}</td>
                        <td>{{ (training.actual_date)?training.actual_date:'N/A' }}</td>
                        <td>{{ (training.actual_cost_formatted)?training.actual_cost_formatted:'N/A' }}</td>
                        <td>{{ (training.completed)? 'Yes':'No' }}</td>
                        <td class="text-center">
                          <router-link
                            v-bind:to="getEditRoute(training.id)"
                            class="btn btn-sm btn-warning"
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </router-link>
                          <router-link
                            v-bind:to="getShowRoute(training.id)"
                            class="btn btn-sm btn-info"
                          >
                            <i class="fas fa-eye"></i>
                          </router-link>
                          <button class="btn btn-sm btn-danger" @click="deleteTraining(training)">
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
.wrap {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.left,
.right {
  flex: 1;
}
</style>
<script>
import dataTables from "datatables";

export default {
  methods: {
    selectAllUsers() {
      this.selectedUsers = this.users;
    },
    resetSelectUsers() {
      this.selectedUsers = [];
    },
    resetForm() {
      this.training = new Form({
        user_id: "",
        method_id: "",
        topic: "",
        completed: 0,
        comment: "",
        expected_date: "",
        expected_cost: "",
        actual_date: "",
        actual_cost: ""
      });
    },
    getShowRoute(id) {
      return "/training/" + id + "/show";
    },
    getEditRoute(id) {
      return "/training/" + id + "/edit";
    },
    handleSelectUser(user) {
      if (!this.selectedUsers.includes(user)) {
        this.selectedUsers.push(user);
      } else {
        this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
      }
    },
    checkValidity() {
      if (!this.selectedUsers.length) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Users is required"
        });

        return false;
      }

      if (!this.training.topic) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Topic is required"
        });

        return false;
      }

      if (!this.training.method_id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Method is required"
        });

        return false;
      }
      if (!this.training.expected_date) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Expected Date is required"
        });
        return false;
      }

      if (!this.training.expected_cost) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Expected Cost is required"
        });
        return false;
      }

      return true;
    },
    createTraining() {
      if (this.checkValidity()) {
        // append user_id
        // this refer to array of users ids
        this.training.user_id = this.selectedUsers.map(user => {
          return user.id;
        });

        this.$Progress.start();
        this.training
          .post("/api/trainings")
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            this.resetForm();
            // load the new data

            if (this.trainings.length) {
              this.table.destroy();
            }
            this.trainings = response.data.data;
            setTimeout(() => {
              this.table = $(".table").DataTable({
                dom: "Blfrtip",
                retrieve: true,
                pageLength: 20,
                order: false,
                columnDefs: [
                  {
                    targets: 8,
                    orderable: false
                  }
                ],
                buttons: [
                  {
                    extend: "csv",
                    text: '<i class="fas fa-file-csv"></i>',
                    exportOptions: {
                      columns: [0, 1, 2, 3, 4, 5, 6, 7]
                    }
                  },
                  {
                    extend: "excel",
                    text: '<i class="fas fa-file-excel"></i>',
                    exportOptions: {
                      columns: [0, 1, 2, 3, 4, 5, 6, 7]
                    }
                  }
                ],
                initComplete: function() {
                  this.api()
                    .columns([0])
                    .every(function() {
                      var column = this;
                      var select = $(
                        '<select><option value="">All Users</option></select>'
                      )
                        .appendTo($(column.header()).empty())
                        .on("change", function() {
                          var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                          );

                          column
                            .search(val ? "^" + val + "$" : "", true, false)
                            .draw();
                        });

                      column
                        .data()
                        .unique()
                        .sort()
                        .each(function(d, j) {
                          select.append(
                            '<option value="' + d + '">' + d + "</option>"
                          );
                        });
                    });
                }
              });
            }, 0);
            // load the new data
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    loadUsers() {
      axios({
        method: "get",
        url: "/api/users"
      }).then(
        response => {
          this.users = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadMethods() {
      axios({
        method: "get",
        url: "/api/methods"
      }).then(
        response => {
          this.methods = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },

  data() {
    return {
      users: [],
      selectedUsers: [],
      training: new Form({
        user_id: "",
        method_id: "",
        topic: "",
        completed: 0,
        comment: "",
        expected_date: "",
        expected_cost: "",
        actual_date: "",
        actual_cost: ""
      }),
      methods: [],
      trainings: [],
      table: ""
    };
  },
  mounted() {
    this.loadUsers();
    this.loadMethods();
  },
  watch: {
    selectedUsers: function(newVal, oldVal) {
      this.selectedUsers = newVal;
    }
  }
};
</script>
