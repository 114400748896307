<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Competencies</h3>
            <div class="card-tools">
              <button
                type="button"
                class="btn btn-sm btn-success"
                data-toggle="modal"
                data-target="#add-competency"
              >
                <i class="fas fa-plus-circle"></i> New
              </button>
            </div>
          </div>
          <div class="card-body p-0 m-2">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Description</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="competency in competencies" v-bind:key="competency.id">
                  <td>{{ competency.name }}</td>
                  <td>{{ competenciesTypes[competency.type]['name'] }}</td>
                  <td>{{ (competency.description)?competency.description:'N/A' }}</td>
                  <td>
                    <button
                      @click="editCompetency(competency)"
                      type="button"
                      class="btn btn-sm btn-warning"
                      data-toggle="modal"
                      data-target="#edit-competency"
                    >
                      <i class="fas fa-pencil-alt"></i>
                      Edit
                    </button>
                    <button class="btn btn-sm btn-danger" @click="deleteCompetency(competency)">
                      <i class="fas fa-trash"></i>
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>

    <!-- add competency Modal -->
    <div
      class="modal fade"
      id="add-competency"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addcompetencyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addcompetencyLabel">Create competency</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form v-on:submit.prevent>
            <div class="modal-body">
              <div class="form-group">
                <label for="add-name">
                  Name
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': form.errors.has('name') }"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  id="add-name"
                  placeholder="Enter name"
                />
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label for="add-type-id">
                  Type
                  <code>*</code>
                </label>
                <select
                  :class="{ 'is-invalid': form.errors.has('type') }"
                  v-model="form.type"
                  class="form-control"
                  id="add-type-id"
                >
                  <option value selected>Select type</option>
                  <option
                    v-for="type in competenciesTypes"
                    v-bind:value="type.id"
                    v-bind:key="type.id"
                  >{{ type.name }}</option>
                </select>
                <has-error :form="form" field="type"></has-error>
              </div>
              <div class="form-group">
                <label for="add-description">Description</label>
                <textarea
                  :class="{ 'is-invalid': form.errors.has('description') }"
                  v-model="form.description"
                  type="text"
                  rows="3"
                  class="form-control"
                  id="add-description"
                  placeholder="Enter description"
                ></textarea>
                <has-error :form="form" field="description"></has-error>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="storeCompetency()" type="button" class="btn  btn-primary">Save</button>

              <button type="button" class="btn  btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit competency Modal -->
    <div
      class="modal fade"
      id="edit-competency"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editCompetencyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editCompetencyLabel">Edit competency</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form v-on:submit.prevent>
            <div class="modal-body">
              <div class="form-group">
                <label for="edit-name">
                  Name
                  <code>*</code>
                </label>
                <input
                  :class="{ 'is-invalid': competency.errors.has('name') }"
                  v-model="competency.name"
                  type="text"
                  class="form-control"
                  id="edit-name"
                  placeholder="Enter name"
                />
                <has-error :form="competency" field="name"></has-error>
              </div>
              <div class="form-group">
                <label for="edit-type-id">
                  Type
                  <code>*</code>
                </label>
                <select
                  :class="{ 'is-invalid': competency.errors.has('type') }"
                  v-model="competency.type"
                  class="form-control"
                  id="edit-type-id"
                >
                  <option
                    v-for="type in competenciesTypes"
                    v-bind:value="type.id"
                    v-bind:key="type.id"
                  >{{ type.name }}</option>
                </select>
                <has-error :form="competency" field="type"></has-error>
              </div>
              <div class="form-group">
                <label for="edit-description">Description</label>
                <textarea
                  :class="{ 'is-invalid': competency.errors.has('description') }"
                  v-model="competency.description"
                  type="text"
                  class="form-control"
                  id="edit-description"
                  rows="3"
                  placeholder="Enter description"
                ></textarea>
                <has-error :form="competency" field="description"></has-error>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="updateCompetency()" type="button" class="btn  btn-primary">Save</button>
              <button type="button" class="btn  btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataTables from "datatables";

export default {
  methods: {
    checkAddValidity() {
      if (!this.form.name) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      if (!this.form.type) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Type is required"
        });

        return false;
      }
      return true;
    },
    checkEditValidity() {
      if (!this.competency.name) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Name is required"
        });

        return false;
      }

      return true;
    },
    storeCompetency() {
      if (this.checkAddValidity()) {
        this.$Progress.start();
        this.form
          .post("/api/competencies")
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            this.loadCompetencies(true);
            $(".dataTables_empty").hide();

            $("#add-competency").modal("toggle"); // close the modal
            // reset
            this.form.name = "";
            this.form.type = "";
            this.form.description = "";
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    editCompetency(competency) {
      this.competency = new Form(competency);
    },
    updateCompetency() {
      if (this.checkEditValidity()) {
        this.$Progress.start();
        this.competency
          .put("/api/competencies/" + this.competency.id)
          .then(response => {
            Toast.fire({
              icon: "success",
              title: response.data.message
            });
            this.$Progress.finish();
            $("#edit-competency").modal("toggle"); // close the modal
            this.loadCompetencies(true);
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    deleteCompetency(competency) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          axios
            .delete("/api/competencies/" + competency.id)
            .then(response => {
              this.loadCompetencies(true);
              Toast.fire({
                icon: "success",
                title: response.data.message
              });
              this.$Progress.finish();
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message
              });
              this.$Progress.fail();
            });
        }
      });
    },
    loadCompetencies(change = false) {
      axios({
        method: "get",
        url: "/api/competencies"
      }).then(
        response => {
          if (change) {
            this.table.destroy();
          }
          this.competencies = response.data.data;
          setTimeout(() => {
            this.table = $(".table").DataTable({
              retrieve: true,
              dom: "Blfrtip",
              buttons: [
                {
                  extend: "csv",
                  text: '<i class="fas fa-file-csv"></i>',
                  exportOptions: {
                    columns: [0, 1, 2]
                  }
                },
                {
                  extend: "excel",
                  text: '<i class="fas fa-file-excel"></i>',
                  exportOptions: {
                    columns: [0, 1, 2]
                  }
                }
              ]
            });
          }, 0);
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  data() {
    return {
      form: new Form({
        name: "",
        type: "",
        description: ""
      }),
      id: 2,
      competency: new Form({
        id: "",
        name: "",
        type: "",
        description: ""
      }),
      // this will be static as it does not change and has only two values
      competenciesTypes: {
        1: {
          id: 1,
          name: "Business"
        },
        2: {
          id: 2,
          name: "Technical"
        }
      },

      competencies: [],
      table: ""
    };
  },
  mounted() {
    this.loadCompetencies();
  }
};
</script>
