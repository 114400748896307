<template>
    <div class="row">
        <div class="col-md-12">
            <section class="content">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Create New Report</h3>
                        <div class="card-tools"></div>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label" for="date">
                                    Date
                                    <code>*</code>
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        :class="{ 'is-invalid': report.errors.has('date') }"
                                        v-model="report.date"
                                        type="date"
                                        class="form-control"
                                        id="date"
                                        placeholder="Enter Date"
                                    />
                                    <has-error :form="report" field="date"></has-error>
                                </div>
                            </div>
                            <div
                                class="form-group row"
                                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount()>1)"
                            >
                                <label class="col-sm-2 col-form-label" for="company_id">
                                    Company
                                    <code>*</code>
                                </label>
                                <div class="col-sm-10">
                                    <select
                                        :class="{ 'is-invalid': report.errors.has('company_id') }"
                                        v-model="report.company_id"
                                        class="form-control"
                                        id="company_id"
                                    >
                                        <option value selected>Select Company</option>
                                        <option
                                            v-for="company in companies"
                                            v-bind:value="company.id"
                                            v-bind:key="company.id"
                                        >{{ company.name}}
                                        </option>
                                    </select>
                                </div>
                                <has-error :form="report" field="company_id"></has-error>
                            </div>
                            <fieldset class="form-group">
                                <div class="row">
                                    <label class="col-form-label col-sm-2 pt-0">
                                        Type
                                        <code>*</code>
                                    </label>
                                    <div class="col-sm-10">
                                        <div class="form-check">
                                            <input
                                                v-model="report.type"
                                                class="form-check-input"
                                                type="radio"
                                                name="type"
                                                id="type1"
                                                v-bind:value="true"
                                                checked
                                            />
                                            <label class="form-check-label" for="type1">Individual</label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                v-model="report.type"
                                                class="form-check-input"
                                                type="radio"
                                                name="type"
                                                id="type2"
                                                v-bind:value="false"
                                            />
                                            <label class="form-check-label" for="type2">Team</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <div class="form-group row" v-if="report.type">
                                <label class="col-sm-2 col-form-label" for="user_id">
                                    User
                                    <code>*</code>
                                </label>
                                <div class="col-sm-10">
                                    <select
                                        :class="{ 'is-invalid': report.errors.has('user_id') }"
                                        v-model="report.user_id"
                                        class="form-control"
                                        id="user_id"
                                    >
                                        <option value selected>Select User</option>
                                        <option
                                            v-for="user in filteredUsers"
                                            v-bind:value="user.id"
                                            v-bind:key="user.id"
                                        >{{ user.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row" v-if="report.user_id">
                                <label class="col-sm-2 col-form-label" for="role_id">
                                    Role
                                    <code>*</code>
                                </label>
                                <div class="col-sm-10">
                                    <select
                                        :class="{ 'is-invalid': report.errors.has('role_id') }"
                                        v-model="report.role_id"
                                        class="form-control"
                                        id="role_id"
                                    >
                                        <option value selected>Select Role</option>
                                        <option
                                            v-for="role in filteredRoles"
                                            v-bind:value="role.id"
                                            v-bind:key="role.id"
                                        >{{ role.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group row" v-if="!report.type">
                                <label class="col-sm-2 col-form-label" for="teams">
                                    Team
                                    <code>*</code>
                                </label>
                                <div class="col-sm-10">
                                    <select
                                        :class="{ 'is-invalid': report.errors.has('team_id') }"
                                        class="custom-select"
                                        id="teams"
                                        v-model="report.team_id"
                                    >
                                        <option value selected>Select Team</option>
                                        <option
                                            v-for="team in filteredTeams"
                                            v-bind:value="team.id"
                                            v-bind:key="team.id"
                                        >{{ team.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row" v-if="report.type&&report.user_id">
                                <div class="col-6" v-if="report.businessCompetencies.length">
                                    <p>
                                        <strong>Business Competencies</strong>
                                    </p>
                                    <table class="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Competency</th>
                                            <th scope="col">Current value</th>
                                            <th scope="col">Standard value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            v-for="(competency,index) in report.businessCompetencies"
                                            v-bind:key="index"
                                        >
                                            <td>
                                                <label v-bind:for="competency.name">
                                                    {{competency.name }}
                                                    <code>*</code>
                                                </label>
                                            </td>

                                            <td style="width:30%">
                                                <input
                                                    @keyup="$inputIdentifyColorAndFeedBack($event)"
                                                    @input="$inputIdentifyColorAndFeedBack($event)"
                                                    @click="$inputIdentifyColorAndFeedBack($event)"
                                                    v-model="report.skills[competency.id]['calculated_value']"
                                                    type="number"
                                                    class="form-control bg-white auto-lick-to-fire-event"
                                                    v-bind:id="report.businessCompetencies[competency.name]"
                                                />
                                            </td>
                                            <td style="width:30%">
                                                <input
                                                    v-model="report.businessCompetencies[index]['standard_value']"
                                                    type="number"
                                                    class="form-control bg-white"
                                                    v-bind:id="report.businessCompetencies[competency.name]"
                                                    readonly
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6" v-if="report.technicalCompetencies.length">
                                    <p>
                                        <strong>Technical Competencies</strong>
                                    </p>

                                    <table class="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Competency</th>
                                            <th scope="col">Current value</th>
                                            <th scope="col">Standard value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            v-for="(competency,index) in report.technicalCompetencies"
                                            v-bind:key="index"
                                        >
                                            <td>
                                                <label v-bind:for="competency.name">
                                                    {{
                                                    competency.name }}
                                                    <code>*</code>
                                                </label>
                                            </td>

                                            <td style="width:30%">
                                                <input
                                                    @keyup="$inputIdentifyColorAndFeedBack($event)"
                                                    @input="$inputIdentifyColorAndFeedBack($event)"
                                                    @click="$inputIdentifyColorAndFeedBack($event)"
                                                    v-model="report.skills[competency.id]['calculated_value']"
                                                    type="number"
                                                    class="form-control bg-white auto-lick-to-fire-event"
                                                    v-bind:id="report.technicalCompetencies[competency.name]"
                                                />
                                            </td>
                                            <td style="width:30%">
                                                <input
                                                    v-model="report.technicalCompetencies[index]['standard_value']"
                                                    type="number"
                                                    class="form-control bg-white"
                                                    v-bind:id="report.technicalCompetencies[competency.name]"
                                                    readonly
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-10">
                                    <button
                                        v-on:click="createReport"
                                        type="submit"
                                        class="btn  btn-primary"
                                    >Save
                                    </button>
                                    <router-link to="/reports" class="btn  btn-danger">Cancel</router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<style scoped>
    .col-form-label {
        white-space: nowrap;
    }
</style>
<script>
    export default {
        methods: {
            createReport() {
                if (this.checkValidity()) {
                    this.fixFormData();

                    this.$Progress.start();
                    this.report
                        .post("/api/reports")
                        .then(response => {
                            Toast.fire({
                                icon: "success",
                                title: response.data.message
                            });
                            this.$Progress.finish();
                            this.$router.push({name: "reports.index"});
                        })
                        .catch(error => {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message
                            });
                            this.$Progress.fail();
                        });
                }
            },


            setDataWithCurrent() {
                let now = new Date();
                let day = ("0" + now.getDate()).slice(-2);
                let month = ("0" + (now.getMonth() + 1)).slice(-2);
                this.report.date = now.getFullYear() + "-" + month + "-" + day;
            },
            checkValidity() {
                // check the values of calculated value between 0 and 100
                for (let key in this.report.skills) {
                    if (this.report.skills.hasOwnProperty(key)) {
                        // console.log(`${key} : ${report.skills[key]}`);
                        let calculatedValue = parseInt(
                            this.report.skills[key]["calculated_value"]
                        );
                        this.report.skills[key]["calculated_value"] = calculatedValue;

                        if (!calculatedValue) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Calculated value is required"
                            });

                            return false;
                        }

                        if (calculatedValue > 100 || calculatedValue <= 0) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Current value must be between 1 and 100"
                            });

                            return false;
                        }
                    }
                }

                // individual
                // remove data of teams if exists
                if (this.report.type == true && !this.report.user_id) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "User is required"
                    });

                    return false;
                }

                if (this.report.type == false && !this.report.team_id) {
                    // team
                    // remove data of users if exists
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Team is required"
                    });
                }

                return true;
            },
            fixFormData() {
                if (this.report.type == true) {
                    // individual
                    // remove data of teams if exists
                    delete this.report.team_id;
                }
                if (this.report.type == false) {
                    // team
                    // remove data of users if exists
                    delete this.report.skills;
                    delete this.report.competencies;
                    delete this.report.user_id;
                }
            },
            getDataForCreating()
            {
                axios({
                    method: "get",
                    url: "/api/reports/create"
                }).then(
                    response => {
                        this.filteredUsers =this.users = response.data.data.users;
                        this.filteredRoles = this.roles = response.data.data.roles;
                        this.filteredTeams =this.teams = response.data.data.teams;
                        this.companies = response.data.data.companies;
                        this.setDataWithCurrent();
                        if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
                            this.report.company_id = this.$gate.getManagedCompanyId();
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
            }

        },

        data() {
            return {
                users: [],
                filteredUsers: [],
                roles: [],
                filteredRoles: [],
                report: new Form({
                    user_id: "",
                    team_id: "",
                    company_id: "",
                    competencies: {},
                    technicalCompetencies: [],
                    businessCompetencies: [],
                    skills: {},
                    date: "",
                    type: true,
                    role_id: ""
                }),
                teams: [],
                filteredTeams: [],
                companies: []
            };
        },
        mounted() {
            this.getDataForCreating()
        },
        watch: {
            "report.role_id": function (val) {
                this.report.skills = {}; //reset
                this.report.businessCompetencies = []; //reset
                this.report.technicalCompetencies = []; //reset
                this.report.competencies.forEach(competency => {
                    if (parseInt(competency.role_id) === parseInt(val)) {
                        this.report.skills[competency.id] = {
                            standard_value: competency.standard_value,
                            calculated_value: ""
                        };
                        // business
                        if (parseInt(competency.type) === 1) {
                            this.report.businessCompetencies.push(competency);
                        } else {
                            // technical => 2
                            this.report.technicalCompetencies.push(competency);
                        }
                    }
                });
            },
            "report.user_id": function (val) {
                let user = this.users.filter(user => {
                    return user.id == val;
                })[0];
                this.report.competencies = user["competencies"];
                this.report.skills = {}; //reset
                this.report.businessCompetencies = []; //reset
                this.report.technicalCompetencies = []; //reset
                this.filteredRoles = [];
                this.report.competencies.forEach(competency => {
                    let role = this.roles.filter(role => {
                        return parseInt(role.id) === parseInt(competency.role_id)
                    })[0];
                    if (!this.filteredRoles.includes(role)) {
                        this.filteredRoles.push(role);
                    }

                    if (parseInt(competency.role_id) === parseInt(this.report.role_id)) {
                        this.report.skills[competency.id] = {
                            standard_value: competency.standard_value,
                            calculated_value: ""
                        };
                        // busness
                        if (parseInt(competency.type) === 1) {
                            this.report.businessCompetencies.push(competency);
                        } else {
                            // technical => 2
                            this.report.technicalCompetencies.push(competency);
                        }
                    }
                });
            },
            "report.company_id": function (val) {
                this.filteredUsers = this.users.filter(user => {
                    return user.company_id == val;
                });

                this.filteredTeams = this.teams.filter(team => {
                    return team.company_id == val;
                });
            }
        }
    };
</script>
