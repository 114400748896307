<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box">
          <span class="info-box-icon bg-info elevation-1">
            <router-link to="/reports">
              <i class="fas fa-cog"></i>
            </router-link>
          </span>

          <div class="info-box-content">
            <span class="info-box-text">Reports</span>
            <span class="info-box-number">{{ data.statistics.reports }}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-danger elevation-1">
            <router-link to="/training">
              <i class="fas fa-chalkboard-teacher"></i>
            </router-link>
          </span>

          <div class="info-box-content">
            <span class="info-box-text">Trainings</span>
            <span class="info-box-number">{{ data.statistics.trainings }}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->

      <!-- fix for small devices only -->
      <div class="clearfix hidden-md-up"></div>

      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-success elevation-1">
            <router-link to="/team">
              <i class="fas fa-users"></i>
            </router-link>
          </span>

          <div class="info-box-content">
            <span class="info-box-text">Teams</span>
            <span class="info-box-number">{{ data.statistics.teams }}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-warning elevation-1">
            <router-link to="/users">
              <i class="fas fa-users"></i>
            </router-link>
          </span>

          <div class="info-box-content">
            <span class="info-box-text">users</span>
            <span class="info-box-number">{{ data.statistics.users }}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
    </div>
    <div class="row">
      <div class="col-md-6">
        <!-- USERS LIST -->
        <div class="card">
          <div class="card-header">
            <h3
              class="card-title"
              v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
            >Latest Companies</h3>
            <h3
              class="card-title"
              v-if="$gate.isAdmin()||$gate.isAdmin() && $gate.IsAdminForOneCompany()"
            >You Company</h3>

            <div class="card-tools">
              <button type="button" data-card-widget="collapse" class="btn btn-tool">
                <i class="fas fa-minus"></i>
              </button>
              <button type="button" data-card-widget="remove" class="btn btn-tool">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <ul class="users-list clearfix">
              <li v-for="company in data.companies" v-bind:key="company.id">
                <img class="img-thumbnail" v-bind:src="company.logo" alt="Company Image" />
                <span class="users-list-name">{{company.name}}</span>
                <span class="users-list-date">{{company.country}}</span>
              </li>
            </ul>
            <!-- /.users-list -->
          </div>
          <!-- /.card-body -->
          <div class="card-footer text-center">
            <router-link
              to="/companies"
              class="uppercase"
              v-if="$gate.isMaster()"
            >View All Companies</router-link>
            <div v-if="!$gate.isMaster()">Welcome</div>
          </div>
          <!-- /.card-footer -->
        </div>
        <!--/.card -->
      </div>
      <div class="col-md-6">
        <!-- USERS LIST -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Latest Users</h3>

            <div class="card-tools">
              <button type="button" data-card-widget="collapse" class="btn btn-tool">
                <i class="fas fa-minus"></i>
              </button>
              <button type="button" data-card-widget="remove" class="btn btn-tool">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <ul class="users-list clearfix">
              <li v-for="user in data.users" v-bind:key="user.id">
                <img v-bind:src="user.logo" alt="User Image" />
                <span class="users-list-name">{{user.name}}</span>
                <span
                  v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                  class="users-list-date"
                >{{ (user.company)?user.company.name:'N/A' }}</span>
              </li>
            </ul>
            <!-- /.users-list -->
          </div>
          <!-- /.card-body -->
          <div class="card-footer text-center">
            <router-link to="/users" class="uppercase">View All Users</router-link>
          </div>
          <!-- /.card-footer -->
        </div>

        <!--/.card -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <!-- USERS LIST -->

        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Latest Branches</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-tool" data-card-widget="remove">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <ul class="products-list product-list-in-card pl-2 pr-2">
              <li class="item" v-for="branch in data.branches" v-bind:key="branch.id">
                <div class="product-info">
                  <span class="product-title">
                    {{branch.name}}
                    <span class="badge float-right">{{branch.created_at}}</span>
                  </span>
                  <span
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                    class="product-description"
                  >{{branch.company.name}}</span>
                </div>
              </li>

              <!-- /.item -->
            </ul>
          </div>
          <!-- /.card-body -->
          <div class="card-footer text-center">
            <router-link to="/branches" class="uppercase">View All Branches</router-link>
          </div>
          <!-- /.card-footer -->
        </div>

        <!--/.card -->
      </div>
      <div class="col-md-6">
        <!-- USERS LIST -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Latest Teams</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-tool" data-card-widget="remove">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <ul class="products-list product-list-in-card pl-2 pr-2">
              <li v-for="team in data.teams" v-bind:key="team.id" class="item">
                <div class="product-info">
                  <span class="product-title">
                    {{team.name}}
                    <span class="badge float-right">{{team.created_at}}</span>
                  </span>
                  <span
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                    class="product-description"
                  >{{team.company.name}}</span>
                </div>
              </li>

              <!-- /.item -->
            </ul>
          </div>
          <!-- /.card-body -->
          <div class="card-footer text-center">
            <router-link to="/team" class="uppercase">View All Teams</router-link>
          </div>
          <!-- /.card-footer -->
        </div>
        <!--/.card -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <!-- USERS LIST -->

        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Latest Reports</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-tool" data-card-widget="remove">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <ul class="products-list product-list-in-card pl-2 pr-2">
              <li v-for="report in data.reports" v-bind:key="report.id" class="item">
                <div class="product-info">
                  <span href="javascript:void(0)" class="product-title">
                    <router-link
                      class="uppercase"
                      v-bind:to="getShowReportRoute(report.id,(report.user)? 1 : 0)"
                    >{{(report.user)?report.user:report.team}}</router-link>
                    <span class="badge float-right">{{report.date}}</span>
                  </span>
                  <span
                    v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                    class="product-description"
                  >{{(report.user&&report.company)?report.company:report.company}}</span>
                </div>
              </li>
            </ul>
          </div>
          <!-- /.card-body -->
          <div class="card-footer text-center">
            <router-link to="/reports" class="uppercase">View All Reports</router-link>
          </div>
          <!-- /.card-footer -->
        </div>

        <!--/.card -->
      </div>
      <div class="col-md-6">
        <!-- USERS LIST -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Latest Trainings</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-tool" data-card-widget="remove">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <ul class="products-list product-list-in-card pl-2 pr-2">
              <li v-for="training in data.trainings" v-bind:key="training.id" class="item">
                <div class="product-info">
                  <span href="javascript:void(0)" class="product-title">
                    <router-link
                      class="uppercase"
                      v-bind:to="getShowTrainingRoute(training.id)"
                    >{{ training.user.name }}</router-link>
                    <span class="badge float-right">{{ training.expected_date }}</span>
                  </span>
                  <span class="product-description">{{ training.topic }}</span>
                </div>
              </li>

              <!-- /.item -->
            </ul>
          </div>
          <!-- /.card-body -->
          <div class="card-footer text-center">
            <router-link to="/training" class="uppercase">View All Trainings</router-link>
          </div>
          <!-- /.card-footer -->
        </div>
        <!--/.card -->
      </div>
    </div>
  </div>
</template>
<style scoped>
.img-thumbnail {
  height: 70px;
}
</style>
<script>
export default {
  data() {
    return {
      data: {
        statistics: {},
        companies: [],
        reports: [],
        users: [],
        teams: [],
        trainings: []
      }
    };
  },
  mounted() {
    axios({
      method: "get",
      url: "/api/dashboard"
    }).then(
      response => {
        this.data = response.data.data;
      },
      error => {
        console.log(error);
      }
    );
  },
  methods: {
    getShowReportRoute(id, type) {
      return "/reports/" + id + "/show" + "?type=" + type;
    },
    getShowTrainingRoute(id) {
      return "/training/" + id + "/show";
    }
  }
};
</script>
