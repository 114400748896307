<template>
  <div class="row">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Search</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body p-0">
            <div class="containter-fluid ml-4 mr-1">
              <div
                class="form-group row mt-1"
                v-if="$gate.isMaster()||($gate.isAdmin()&&$gate.getCompaniesCount()>1)"
              >
                <label class="col-sm-1 col-form-label" for="company_id">Company</label>
                <div class="col-sm-11">
                  <select v-model="company_id" class="form-control" id="company_id">
                    <option value selected>All</option>
                    <option
                      v-for="company in companies"
                      v-bind:value="company.id"
                      v-bind:key="company.id"
                    >{{ company.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  @click="resetCompetencies"
                  class="nav-link active"
                  id="roles-tab"
                  data-toggle="tab"
                  href="#roles"
                  role="tab"
                  aria-controls="roles"
                  aria-selected="true"
                >Roles</a>
              </li>
              <li class="nav-item">
                <a
                  @click="resetCompetencies"
                  class="nav-link"
                  id="Competencies-tab"
                  data-toggle="tab"
                  href="#Competencies"
                  role="tab"
                  aria-controls="Competencies"
                  aria-selected="false"
                >Competencies</a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="roles"
                role="tabpanel"
                aria-labelledby="roles-tab"
              >
                <div class="container-fluid m-2">
                  <form v-on:submit.prevent>
                    <div class="form-group row">
                      <label for="role" class="col-sm-1 col-form-label">
                        Role
                        <code>*</code>
                      </label>
                      <div class="col-sm-11">
                        <select
                          @change="displayCompetencies"
                          v-model="searchRoleId"
                          class="form-control"
                          id="role"
                        >
                          <option value selected>Select Role</option>
                          <option
                            v-for="role in roles"
                            v-bind:key="role.id"
                            v-bind:value="role.id"
                          >{{ role.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        v-for="(competency,index) in searchCompetencies"
                        v-bind:key="competency.id"
                        class="col-sm-3 row mt-1"
                      >
                        <label class="col-sm-7 col-form-label" v-bind:for="competency.name">
                          {{ competency.name
                          }}
                        </label>
                        <input
                          max="100"
                          min="0"
                          :placeholder="competency.name"
                          v-model="searchCompetencies[index].standard_value"
                          type="number"
                          class="col-sm-3"
                        />

                        <button
                          class="btn btn-sm btn-danger ml-2 col-sm-1"
                          @click="removeCompetency(competency)"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        <div class="col-sm-2"></div>
                      </div>
                    </div>
                    <div class="form-group row mt-3" v-if="searchCompetencies.length">
                      <label for="moreCompetencies" class="label col-sm-3">More Competencies?</label>
                      <div class="col-sm-5">
                        <select v-model="moreCompetency" class="form-control" id="moreCompetencies">
                          <option value selected>Select Competency</option>
                          <option
                            v-for="Competency in allCompetenciesFiltered"
                            v-bind:key="Competency.id"
                            v-bind:value="Competency"
                          >{{Competency.name}}</option>
                        </select>
                      </div>
                      <div class="col-sm-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newValueToAdd"
                          placeholder="Enter the value for competency"
                        />
                      </div>
                      <div class="col-sm-2">
                        <button v-on:click="addCompetency()" type="submit" class="btn btn-success">
                          <i class="fas fa-plus"></i> Add
                        </button>
                      </div>
                    </div>
                    <button v-on:click="search" type="submit" class="btn  btn-primary">
                      <i class="fas fa-search"></i> Search
                    </button>
                    <button @click="resetForm" type="reset" class="btn  btn-danger">Reset</button>
                  </form>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="Competencies"
                role="tabpanel"
                aria-labelledby="Competencies-tab"
              >
                <div class="container-fluid m-2">
                  <form v-on:submit.prevent>
                    <div class="form-group row mt-3">
                      <label for="moreCompetenciesSearch" class="label col-sm-3">
                        Competencies
                        <code>*</code>
                      </label>
                      <div class="col-sm-5">
                        <select
                          v-model="moreCompetency"
                          class="form-control"
                          id="moreCompetenciesSearch"
                        >
                          <option value selected>Select Competency</option>

                          <option
                            v-for="Competency in allCompetenciesFiltered"
                            v-bind:value="Competency"
                            v-bind:key="Competency.id"
                          >{{Competency.name}}</option>
                        </select>
                      </div>

                      <div class="col-sm-2">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newValueToAdd"
                          placeholder="Enter the value for competency"
                        />
                      </div>
                      <div class="col-sm-2">
                        <button v-on:click="addCompetency()" type="submit" class="btn btn-success">
                          <i class="fas fa-plus"></i> Add
                        </button>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div
                        v-for="(competency,index) in searchCompetencies"
                        v-bind:key="competency.id"
                        class="col-sm-3 row mt-2"
                      >
                        <label v-bind:for="competency.name" class="col-sm-7 col-form-label">
                          {{ competency.name
                          }}
                        </label>
                        <input
                          max="100"
                          min="0"
                          :placeholder="competency.name"
                          v-model="searchCompetencies[index].standard_value"
                          type="number"
                          class="col-sm-3"
                        />
                        <button
                          class="btn btn-sm btn-danger ml-2 col-sm-1"
                          @click="removeCompetency(competency)"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        <div class="col-sm-2"></div>
                      </div>
                    </div>

                    <button v-on:click="search" type="submit" class="btn btn-primary">
                      <i class="fas fa-search"></i> Search
                    </button>
                    <button @click="resetForm" type="reset" class="btn btn-danger">Reset</button>
                  </form>
                </div>
              </div>
            </div>

            <div class="container-fluid" v-if="users.length">
              <table class="table table-hover stripe">
                <thead>
                  <tr>
                    <th>Rank</th>

                    <th scope="col">Name</th>
                    <th
                      scope="col"
                      v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                    >Company</th>
                    <th scope="col">Role</th>
                    <th class="text-center">Collective Value</th>
                    <th scope="col" class="text-center">Competencies</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user,index) in users" v-bind:key="user.id">
                    <td>{{index+1}}</td>
                    <td>{{ user.name }}</td>
                    <td
                      v-if="$gate.isMaster()||$gate.isAdmin() && $gate.getCompaniesCount()>1"
                    >{{ user.company?user.company.name:'N/A' }}</td>
                    <td>{{ user.role?user.role.name:'N/A' }}</td>
                    <td class="text-center">{{ user.collective_Value }}</td>
                    <td>
                      <table class="table-hover text-center w-100">
                        <thead>
                          <th>Competency</th>
                          <th>Current Value</th>
                          <th>Standard Value</th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="competency in user.report.competencies"
                            v-bind:key="competency.id"
                          >
                            <td>{{ competency['competency']['name'] }}</td>
                            <td>{{ competency['calculated_value'] }}</td>
                            <td>{{ competency['standard_value'] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
.col-form-label {
  white-space: nowrap;
}
</style>
<script>
export default {
  methods: {
    resetCompetencies() {
      this.searchCompetencies = [];
      this.searchRoleId = [];
      this.users = [];
      this.loadRoles();
    },
    search() {
      if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
        this.company_id = this.$gate.getManagedCompanyId();
      }
      if (this.checkSearchValidity()) {
        this.$Progress.start();
        axios
          .get("/api/search", {
            params: {
              competencies: this.searchCompetencies,
              company_id: this.company_id
            }
          })
          .then(response => {
            this.$Progress.finish();

            if (this.users.length) {
              this.table.destroy();
              this.rank = 1; // reset
            }

            this.users = response.data.data;

            setTimeout(() => {
              let tableColumns = [0, 1, 2, 3];
              if (this.$gate.isAdmin() && this.$gate.IsAdminForOneCompany()) {
                tableColumns = [0, 1, 2];
              }
              this.table = $(".table").DataTable({
                dom: "Blfrtip",
                retrieve: true,
                pageLength: 20,
                 columnDefs: [
                {
                  targets: 5,
                  orderable: false
                }
              ],
                buttons: [
                  {
                    extend: "csv",
                    text: '<i class="fas fa-file-csv"></i>',
                    exportOptions: {
                      columns: tableColumns
                    }
                  },
                  {
                    extend: "excel",
                    text: '<i class="fas fa-file-excel"></i>',
                    exportOptions: {
                      columns: tableColumns
                    }
                  }
                ]
              });
            }, 0);

            if (this.users.length) {
              Toast.fire({
                icon: "success",
                title: this.users.length + " User(s) found"
              });
            } else {
              Toast.fire({
                icon: "success",
                title: "No user match your search"
              });
            }
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.$Progress.fail();
          });
      }
    },
    displayCompetencies() {
      let currentRole = this.roles.filter(el => {
        return this.searchRoleId == el.id;
      })[0];

      if (currentRole) {
        this.searchCompetencies = currentRole.competencies;
      } else {
        this.searchCompetencies = [];
      }
    },
    checkSearchValidity() {
      if (this.searchCompetencies.length == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The competencies required"
        });

        return false;
      }
      return true;
    },
    checkValidity() {
      if (!this.moreCompetency) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: " The competency is required"
        });

        return false;
      }

      if (
        parseInt(this.newValueToAdd) > 100 ||
        parseInt(this.newValueToAdd) <= 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: " The standard value must be between 1 and 100"
        });

        return false;
      }

      if (!this.newValueToAdd) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: " The value for competency is required"
        });

        return false;
      }

      return true;
    },
    addCompetency() {
      if (this.checkValidity()) {
        // competency: this.allCompetencies[this.moreCompetency],
        this.searchCompetencies.push({
          name: this.moreCompetency.name,
          id: this.moreCompetency.id,
          standard_value: parseInt(this.newValueToAdd),
          description: this.moreCompetency.description
        });
        this.moreCompetency = ""; //reset
        this.newValueToAdd = ""; //reset
      }
    },

    removeCompetency(competency) {
      this.searchCompetencies.splice(
        this.searchCompetencies.indexOf(competency),
        1
      );
    },
    resetForm() {
      this.searchRoleId = "";
      this.searchCompetencies = [];
      this.newValueToAdd = "";
      this.moreCompetency = "";
      this.loadRoles();
    },

    loadRoles() {
      axios({
        method: "get",
        url: "/api/roles"
      }).then(
        response => {
          this.roles = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadCompetencies() {
      axios({
        method: "get",
        url: "/api/competencies"
      }).then(
        response => {
          this.allCompetencies = response.data.data;
          this.allCompetenciesFiltered = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    loadCompanies() {
      axios({
        method: "get",
        url: "/api/companies"
      }).then(
        response => {
          this.companies = response.data.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },

  data() {
    return {
      companies: [],
      searchRoleId: "",
      searchCompetencies: [],
      rolesCompetencies: {},
      roles: [],
      allCompetencies: {},
      allCompetenciesFiltered: {},
      newValueToAdd: "",
      moreCompetency: "",
      company_id: "",
      users: [],
      table: "",
      rank: 1
    };
  },
  mounted() {
    this.loadRoles();
    this.loadCompetencies();
    this.loadCompanies();
  },
  watch: {
    // to filter more competency with the rest of competencies
    searchCompetencies: function(val) {
      let currentCompetenciesIds = val.map(e => {
        return e.id;
      });
      this.allCompetenciesFiltered = this.allCompetencies.filter(
        el => !currentCompetenciesIds.includes(el.id)
      );
    }
  }
};
</script>
