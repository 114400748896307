<template>
  <div class="row" id="content">
    <div class="col-md-12">
      <section class="content">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">{{ this.evaluation.entity }} Report</h3>
            <div class="card-tools"></div>
          </div>
          <div class="card-body">
            <form v-on:submit.prevent>
              <div class="form-row">
                <div class="form-group col-md-6 row">
                  <label for="name" class="col-sm-2">Name</label>
                  <div class="col-sm-10">{{evaluation.entity}}</div>
                </div>
                <div class="form-group col-md-6 row" v-if="evaluation.role">
                  <label for="name" class="col-sm-2">Role</label>
                  <div class="col-sm-10">{{evaluation.role}}</div>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-5">
                  <p>
                    <strong>Business Competencies</strong>
                  </p>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Competency</th>
                        <th scope="col">Current value</th>
                        <th scope="col">Standard value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(competency,index) in evaluation.report.businessCompetencies"
                        v-bind:key="index"
                      >
                        <td>
                          <label
                            v-bind:for="competency.competency.name"
                          >{{competency.competency.name }}</label>
                        </td>

                        <td style="width:30%">
                          <input
                            @keyup="$inputIdentifyColorAndFeedBack($event)"
                            @input="$inputIdentifyColorAndFeedBack($event)"
                            @click="$inputIdentifyColorAndFeedBack($event)"
                            v-model="evaluation.report.businessCompetencies[index]['calculated_value']"
                            type="number"
                            class="form-control bg-white auto-lick-to-fire-event"
                            v-bind:id="evaluation.report.businessCompetencies[competency.name]"
                            readonly
                          />
                        </td>
                        <td style="width:30%">
                          <input
                            v-model="evaluation.report.businessCompetencies[index]['standard_value']"
                            type="number"
                            class="form-control bg-white"
                            v-bind:id="evaluation.report.businessCompetencies[competency.name]"
                            readonly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="width: 100%;height: 500px">
                    <canvas id="radar-businessCompetencies"></canvas>
                  </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                  <p>
                    <strong>Technical Competencies</strong>
                  </p>

                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Competency</th>
                        <th scope="col">Current value</th>
                        <th scope="col">Standard value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(competency,index) in evaluation.report.technicalCompetencies"
                        v-bind:key="index"
                      >
                        <td>
                          <label v-bind:for="competency.competency.name">
                            {{
                            competency.competency.name }}
                          </label>
                        </td>

                        <td style="width:30%">
                          <input
                            @keyup="$inputIdentifyColorAndFeedBack($event)"
                            @input="$inputIdentifyColorAndFeedBack($event)"
                            @click="$inputIdentifyColorAndFeedBack($event)"
                            v-model="evaluation.report.technicalCompetencies[index]['calculated_value']"
                            type="number"
                            class="form-control bg-white auto-lick-to-fire-event"
                            v-bind:id="evaluation.report.technicalCompetencies[competency.name]"
                            readonly
                          />
                        </td>
                        <td style="width:30%">
                          <input
                            v-model="evaluation.report.technicalCompetencies[index]['standard_value']"
                            type="number"
                            class="form-control bg-white"
                            v-bind:id="evaluation.report.technicalCompetencies[competency.name]"
                            readonly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="width: 100%;height: 500px">
                    <canvas id="radar-technicalCompetencies"></canvas>
                  </div>
                </div>
              </div>
              <button v-if="false" @click="generatePDF" class="btn btn-sm btn-success">
                <i class="fas fa-file-pdf"></i> PDF
              </button>
              <router-link to="/reports" class="btn btn-sm btn-danger float-right ml-1">Cancel</router-link>

              <button @click="printWindow" class="btn btn-sm btn-primary float-right">
                <i class="fas fa-print"></i> Print
              </button>

              <router-link
                v-if="type"
                v-bind:to="getEditRoute()"
                class="mr-1 btn btn-sm btn-warning float-right"
              >
                <i class="fas fa-pencil-alt"></i> Edit
              </router-link>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
@media print {
  .btn {
    display: none;
  }
}
</style>
<script>
import Chart from "chart.js";
import jsPDF from "jspdf";

export default {
  methods: {
    getEditRoute() {
      return "/reports/" + this.$route.params.id + "/edit";
    },
    printWindow: function() {
      window.print();
    },
    checkValidity() {
      if (parseInt(this.$route.query.type) == 1) {
        this.type = 1;
      } else if (parseInt(this.$route.query.type) == 0) {
        this.type = 0;
      }
    },
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },
    generatePDF() {
      var HTML_Width = $("#content").width();
      var HTML_Height = $("#content").height();
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + top_left_margin * 2;
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;
      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      html2canvas($("#content")[0]).then(function(canvas) {
        canvas.getContext("2d");
        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF("l", "pt", [PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );
        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(
            imgData,
            "JPG",
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
        }
        pdf.save("report.pdf");
      });
    },
    loadEntity() {
      let vim = this;
      axios({
        method: "get",
        url: "/api/reports/" + this.$route.params.id + "?type=" + vim.type
      })
        .then(response => {
          this.evaluation = response.data.data;
          if (vim.type) {
            this.evaluation.entity = this.evaluation.user;
          }
          if (!vim.type) {
            vim.evaluation.entity = this.evaluation.team;
          }
          this.drawChart();
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    drawChart() {
      let businessLabels = [];
      let businessSData = [];
      let businessCData = [];
      let technicalLabels = [];
      let technicalSData = [];
      let technicalCData = [];
      this.evaluation.report.businessCompetencies.forEach(competency => {
        businessLabels.push(competency.competency.name);
        businessSData.push(competency.standard_value);
        businessCData.push(competency.calculated_value);
      });
      this.evaluation.report.technicalCompetencies.forEach(competency => {
        technicalLabels.push(competency.competency.name);
        technicalSData.push(competency.standard_value);
        technicalCData.push(competency.calculated_value);
      });

      const businessCompetenciesData = {
        type: "radar",
        data: {
          labels: businessLabels,
          datasets: [
            {
              label: "SV",
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 0.2)",
              pointBackgroundColor: "rgba(54, 162, 235, 0.2)",
              data: businessSData
            },
            {
              label: "CV",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 0.2)",
              pointBackgroundColor: "rgba(255, 99, 132, 0.2)",
              data: businessCData
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "top"
          },
          title: {
            display: true,
            text: "Business Competencies Radar Chart"
          },
          scale: {
            ticks: {
              beginAtZero: true
            }
          }
        }
      };
      const technicalCompetenciesData = {
        type: "radar",
        data: {
          labels: technicalLabels,
          datasets: [
            {
              label: "SV",
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 0.2)",
              pointBackgroundColor: "rgba(54, 162, 235, 0.2)",
              data: technicalSData
            },
            {
              label: "CV",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 0.2)",
              pointBackgroundColor: "rgba(255, 99, 132, 0.2)",
              data: technicalCData
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "top"
          },
          title: {
            display: true,
            text: "Technical Competencies Radar Chart"
          },
          scale: {
            ticks: {
              beginAtZero: true
            }
          }
        }
      };
      this.createChart("radar-businessCompetencies", businessCompetenciesData);
      this.createChart(
        "radar-technicalCompetencies",
        technicalCompetenciesData
      );
    }
  },

  data() {
    return {
      type: "",
      evaluation: {
        entity: "",
        report: {
          businessCompetencies: [],
          technicalCompetencies: []
        }
      }
    };
  },
  mounted() {
    this.checkValidity();
    this.loadEntity();
    // to show the value indeicator
    setTimeout(function() {
      $(".auto-lick-to-fire-event").trigger("click");
    }, 1000);
  }
};
</script>
